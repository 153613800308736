import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import React, { useState } from "react";

export type PasswordDialogProps = {
    "initialValue"?: string;
    "error"?: string;
    "onSubmit": (input: { "password": string }) => void;
};

export const PasswordDialog: React.FC<PasswordDialogProps> = ({
    initialValue = "",
    error,
    onSubmit,
}) => {
    const [password, setPassword] = useState(initialValue);
    const submit = () => onSubmit({ password });
    return (
        <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Password Required:"}</DialogTitle>
            <DialogContent>
                <TextField
                    autoComplete="off"
                    autoFocus
                    error={Boolean(error)}
                    helperText={error}
                    margin="dense"
                    id="password"
                    label="Password"
                    type="password"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                    onKeyUp={({ keyCode }) => keyCode === 13 && submit()}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={submit} color="primary">
                    {"Submit"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
