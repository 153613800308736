import React, { Fragment, useContext, useRef } from "react";
import { AccountNumberSubmissionForm } from "../components/account_number_submission_form";
import { AlternateSubscriptionMethods } from "../components/alternate_subscription_methods";
import { ClientCustomizations } from "../context/client_customizations";
import { SignupPermissions } from "../types/signup_permissions";

export const LandingPage: React.FC<SignupPermissions> = (props) => {
    const { landingPageIntro } = useContext(ClientCustomizations);
    const introProps = useRef({});

    return (
        <Fragment>
            <div data-testid="landingPageIntro">
                {landingPageIntro(introProps.current)}
            </div>
            <AccountNumberSubmissionForm />
            <AlternateSubscriptionMethods {...props} />
        </Fragment>
    );
};
