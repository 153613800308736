import { FormLabel, InputProps, TextField } from "@material-ui/core";
import React from "react";

export type TextBoxProps<T, N extends keyof T = keyof T> = {
    "data-testid"?: string | keyof T;
    "error"?: string;
    "fullWidth"?: boolean;
    "label": string;
    "name": N | string;
    "onChange": InputProps["onChange"];
    "placeholder": string;
    "required"?: boolean;
    "value": T[N];
};

export function TextBox<T>({
    name,
    "data-testid": dataTestId = name,
    error,
    fullWidth = true,
    label,
    onChange,
    placeholder,
    required = false,
    value,
}: TextBoxProps<T>): JSX.Element {
    return (
        <TextField
            error={Boolean(error)}
            fullWidth={fullWidth}
            helperText={error}
            inputProps={{ "data-testid": dataTestId }}
            label={<FormLabel required={required}>{label}</FormLabel>}
            name={name.toString()}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
        />
    );
}
