import { FormikErrors } from "formik";
import { removeUndefinedProps } from "../services/remove_undefined_props";
import { ManualSubmission } from "../types/subscription/manual_submission";
import { validateZipCode } from "./validate_zip_code";
import { validatePractitionerType } from "./validate_practitioner_type";
import { practitionerTypeDetailMap } from "../services/practitioner_details/practitioner_type_detail_map";

export const validateManualAccount
    = async (entry: ManualSubmission): Promise<FormikErrors<ManualSubmission>> =>
        removeUndefinedProps({
            "city": !entry.city
                ? "Please provide your City"
                : undefined,
            "firstName": !entry.firstName
                ? "Please provide your First Name"
                : undefined,
            "lastName": !entry.lastName
                ? "Please provide your Last Name"
                : undefined,
            "postalCode": !entry.postalCode
                ? "Please provide your Zip Code"
                : !validateZipCode(entry.postalCode)
                    ? "Zip Code must be 5 numeric characters"
                    : undefined,
            "practitionerType": validatePractitionerType(entry.practitionerType)
                ? undefined
                : "Please select your Practitioner Type",
            "siteType": practitionerTypeDetailMap[entry.practitionerType] === "pharmacist" && !entry.siteType
                ? "Please select your Site Type"
                : undefined,
            "specialty": ["physician", "app"].includes(practitionerTypeDetailMap[entry.practitionerType])
                && !entry.specialty
                ? "Please select your Specialty"
                : undefined,
            "state": !entry.state
                ? "Please provide your State"
                : undefined,
            "street1": !entry.street1
                ? "Please provide your Street Address"
                : undefined,
        });
