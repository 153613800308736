import { useState } from "react";
import { ManualSubmission } from "../../types/subscription/manual_submission";
import { validateManualAccount as validate } from "../../validation/validate_manual_account";
import { practitionerTypes } from "../options/practitioner_types";
import { practitionerTypeDetailMap } from "../practitioner_details/practitioner_type_detail_map";
import { ProfessionalDetail, professionalDetails } from "../practitioner_details/professional_details";
import { useForm } from "./use_form";

export function useManualAccountForm({
    initialValues = {
        "city": "",
        "company": "",
        "firstName": "",
        "lastName": "",
        "npiNumber": "",
        "phone": "",
        "postalCode": "",
        "practitionerType": "" as any,
        "siteType": undefined,
        "specialty": undefined,
        "state": "",
        "street1": "",
        "street2": "",
    },
    onSubmit,
}: {
    "initialValues"?: ManualSubmission;
    "onSubmit": (account: ManualSubmission) => Promise<void>;
}) {
    const [professionalDetail, setProfessionalDetail] = useState<ProfessionalDetail | undefined>(
        professionalDetails[practitionerTypeDetailMap[initialValues.practitionerType]]
    );

    const form = useForm({
        initialValues,
        onSubmit,
        validate,
    });

    function setFieldValue<T extends keyof ManualSubmission>(key: T, value: ManualSubmission[T]): void {
        if (key === "practitionerType" && value !== form.values.practitionerType) {
            if (professionalDetail?.type) {
                form.setFieldValue(professionalDetail.type, undefined);
            }
            setProfessionalDetail(professionalDetails[
                practitionerTypeDetailMap[value as ManualSubmission["practitionerType"]]
            ]);
        }
        form.setFieldValue(key, value);
    }

    return {
        ...form,
        practitionerTypes,
        professionalDetail,
        setFieldValue,
    };
}
