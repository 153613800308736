import { Container, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { Image } from "../../../components/image";
import renewHeader from "./assets/Sub_Page_MPR_header_sub_renew.jpg";
import { DefaultFooter } from "../../../components/default_footer";

export const MonthlyPrescribingReferenceRedirect: React.FC = () => {
    return (<Fragment>
        <Container style={{ "padding": "0px" }}>
            <Image alt={"Renew Header"} src={renewHeader} />
        </Container>
        <Container style={{ "maxWidth": "700px", "marginTop": "15px" }}>
            <Typography data-testid="redirectSupportMessage">
                    {`Thank you for your interest in MPR.  
                    At this time we are no longer offering complimentary subscriptions.
                    Please follow the link to our paid subscription form. `}
                    <a href={"https://forms.haymarketsubscribe.com/loading.do?omedasite=MPR_PaidNew"}
                        data-testid="redirectEmail">
                        Click Here
                    </a>
            </Typography>
            <Container style={{ "maxWidth": "700px", "position": "fixed", "bottom": 20 }}>
                <DefaultFooter
                    copyrightInfo="Copyright © 2020 Haymarket Media, Inc. All Rights Reserved"
                    emailAddress="custserv@haymarketmedia.com"
                    mailingAddress="Haymarket Media: 275 7th Ave, New York, NY 10001"
                    termsConditionsUrl="https://www.haymarket.com/terms-and-conditions/"
                    privacyPolicyUrl="https://www.haymarketmediaus.com/haymarket-media-medical-privacy-policy/"
                />
            </Container>
        </Container>
    </Fragment>);
};
