import { createMuiTheme, ThemeOptions } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

const options: ThemeOptions = {
    "palette": {
        "primary": blue,
    },
    "props": {
        "MuiButton": {
            "color": "primary",
            "size": "small",
            "variant": "outlined",
        },
        "MuiContainer": {
            "style": {
                "paddingTop": "1.5em",
                "paddingBottom": "1.5em",
            },
        },
        "MuiGrid": {
            "justify": "space-evenly",
            "spacing": 1,
        },
        "MuiPaper": {
            "style": {
                "marginBottom": "1em",
                "marginTop": "1em",
            },
        },
        "MuiRadio": {
            "color": "primary",
        },
        "MuiTextField": {
            "size": "small",
            "variant": "standard",
            "margin": "normal",
        },
    },
};

export const theme = createMuiTheme(options);
