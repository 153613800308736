import React, { CSSProperties, FC } from "react";

export type ImageProps = {
    "alt": string;
    "src": string;
    "style"?: CSSProperties;
}

const defaultStyle = { "display": "block", "margin": "auto", "maxWidth": "100%" };

export const Image: FC<ImageProps> = ({ alt, src, style }) => (
    <img
        alt={alt}
        src={src}
        style={{ ...defaultStyle, ...style }}
    />
);
