import React, { Fragment } from "react";
import { BackButton } from "../components/back_button";
import { SubscriberInfoDisplay } from "../components/subscriber_info_display";
import { SubscriptionRequestForm } from "../components/subscription_request_form";

export const AccountRenewal: React.FC = () => {
    return (
        <Fragment>
            <SubscriberInfoDisplay />
            <SubscriptionRequestForm />
            <BackButton name="AccountRenewal" />
        </Fragment>
    );
};
