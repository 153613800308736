import React, { Fragment } from "react";
import { AppSearchForm } from "../components/app_search_form";
import { BackButton } from "../components/back_button";

export const AppLookup: React.FC = () => {
    return (
        <Fragment>
            <AppSearchForm />
            <BackButton name="AppLookup" />
        </Fragment>
    );
};
