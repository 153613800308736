import { Button, Container, Grid, Paper } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import React, { useContext } from "react";
import { useAppSearchForm } from "../services/hooks/use_app_search_form";
import { states } from "../services/options/states";
import { AppSearchData } from "../types/requests/app_search_data";
import { Dropdown } from "./dropdown";
import { Loading } from "./loading";
import { ManualFallbackDialog } from "./manual_fallback_dialog";
import { Message } from "./message";
import { TextBox } from "./text_box";
import { ClientCustomizations } from "../context/client_customizations";

export const AppSearchForm: React.FC = () => {
    const {
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        submissionError,
        values,
    } = useAppSearchForm();
    const { appSearchLabel } = useContext(ClientCustomizations);

    return (<Paper>
        <Container>
            <Loading loading={isSubmitting} />
            <form onSubmit={handleSubmit} data-testid="formSearch">
                <Grid container={true}>
                    <Grid item={true} xs={12} data-testid="searchPrompt">
                        {appSearchLabel}
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <TextBox<AppSearchData>
                            data-testid="firstName"
                            error={errors.firstName}
                            label="First Name"
                            name="firstName"
                            onChange={handleChange}
                            placeholder="First Name"
                            value={values.firstName}
                        />
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <TextBox<AppSearchData>
                            data-testid="lastName"
                            error={errors.lastName}
                            label="Last Name"
                            name="lastName"
                            onChange={handleChange}
                            placeholder="Last Name"
                            value={values.lastName}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <TextBox<AppSearchData>
                            data-testid="npiNumber"
                            error={errors.npiNumber}
                            label="NPI Number"
                            name="npiNumber"
                            onChange={handleChange}
                            placeholder="NPI Number"
                            value={values.npiNumber}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Dropdown<AppSearchData>
                            data-testid="state"
                            error={errors.state}
                            label="Mailing State"
                            name="state"
                            options={states}
                            placeholder="Select a State"
                            setFieldValue={setFieldValue}
                        />
                    </Grid>
                    {submissionError && <Grid item={true} xs={12}>
                        <Message
                            content={submissionError}
                            data-testid="submissionError"
                            error={true}
                            header="An Error has occurred with your submission"
                        />
                    </Grid>}
                    <Grid item={true} xs={12}>
                        <Button
                            data-testid="submitSearch"
                            endIcon={<SendIcon />}
                            type="submit">
                            {"Submit"}
                        </Button>
                    </Grid>
                    {submissionError && <ManualFallbackDialog />}
                </Grid>
            </form>
        </Container>
    </Paper >);
};
