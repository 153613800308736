import React, { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import { BackButton } from "../components/back_button";
import { ManualAccountForm } from "../components/manual_account_form";
import { Account } from "../context/account";
import { useRoutes } from "../services/hooks/use_routes";
import { ManualSubmission } from "../types/subscription/manual_submission";

export const ManualSignup: React.FC = () => {
    const routes = useRoutes();
    const history = useHistory();
    const { setAccountByManual } = useContext(Account);
    const onSubmit = async (account: ManualSubmission) => {
        await setAccountByManual(account);
        history.push(routes.accountRenewal);
    };

    return (
        <Fragment>
            <ManualAccountForm onSubmit={onSubmit} />
            <BackButton name="Manual" />
        </Fragment>
    );
};
