import { useState } from "react";
import { AccountInfo } from "../../types/account_info";
import { AccountHcpData } from "../../types/requests/account_hcp_data";
import { ManualSubmission } from "../../types/subscription/manual_submission";
import { SubscriptionAccountMatch } from "../../types/subscription/subscription_account_match";
import { getAccount as defaultGetAccount } from "../account/get_account";
import { removeBlankStringProps } from "../remove_blank_string_props";

type AccountState = {
    "accountInfo": AccountInfo;
    "accountMatch": SubscriptionAccountMatch;
    "id": string;
};

export function useAccount(getAccount = defaultGetAccount,bookId: string | undefined) {    
    const [{ accountInfo, accountMatch, id }, setAccount] = useState({
        "accountInfo": {},
        "accountMatch": {},
    } as AccountState);

    const [changeOfAddress, setChangeOfAddress] = useState(undefined as undefined | ManualSubmission);

    const formatManualToAccountInfo = (input: ManualSubmission): AccountInfo => ({
        "address1": input.street1,
        "address2": input.street2,
        "city": input.city,
        "firstName": input.firstName,
        "formattedName": `${input.firstName} ${input.lastName}`,
        "isPhysician": false,
        "lastName": input.lastName,
        "state": input.state,
        "zip": input.postalCode,
    });

    const setAccountById = (id: string) => getAccount(id,bookId)
        .then((account) => setAccount({
            id,
            "accountInfo": { ...account, "isPhysician": Boolean(account.scrambledMe) },
            "accountMatch": {
                "id": account.dgid,
                "strategy": "dgid",
            },
        }));

    const setAccountBySearch = (rawData: AccountHcpData) => {
        const search = removeBlankStringProps(rawData);
        return getAccount(search)
            .then((account) => setAccount({
                "id": "",
                "accountInfo": { ...account, "isPhysician": Boolean(account.scrambledMe) },
                "accountMatch": {
                    search,
                    "strategy": "search",
                },
            }));
    };

    const setAccountByManual = async (manual: ManualSubmission) => setAccount({
        "id": "",
        "accountInfo": formatManualToAccountInfo(manual),
        "accountMatch": {
            manual,
            "strategy": "manual",
        },
    });

    const hasAccountMatch = Object.keys(accountMatch).length > 0;

    const accountAddress = (changeOfAddress
        ? [
            `${changeOfAddress.firstName} ${changeOfAddress.lastName}`,
            changeOfAddress.street1,
            changeOfAddress.street2,
            `${changeOfAddress.city}, ${changeOfAddress.state} ${changeOfAddress.postalCode}`,
        ]
        : [
            accountInfo.formattedName,
            accountInfo.address1,
            accountInfo.address2,
            `${accountInfo.city}, ${accountInfo.state} ${accountInfo.zip}`,
        ]
    ).filter(Boolean) as string[];

    return {
        accountAddress,
        accountInfo,
        accountMatch,
        changeOfAddress,
        hasAccountMatch,
        id,
        setAccountById,
        setAccountByManual,
        setAccountBySearch,
        setChangeOfAddress,
    };
}
