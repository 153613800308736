import { apiKey } from "../../config/api_key";
import { apiRoutes } from "../../config/api_routes";
import { SubscriptionPostEventBody } from "../../types/requests/subscription_post_event_body";
import { apiPost } from "../http/api_post";

export function postSubscription(subscription: SubscriptionPostEventBody) {
    return apiPost({
        "body": subscription,
        "headers": { "x-api-key": apiKey },
        "url": apiRoutes.postSubscription,
    });
}
