import { createMuiTheme, ThemeOptions } from "@material-ui/core";

const options: ThemeOptions = {
    "props": {
        "MuiButton": {
            "size": "small",
            "variant": "contained",
        },
        "MuiContainer": {
            "style": {
                "paddingTop": "1.5em",
                "paddingBottom": "1.5em",
            },
        },
        "MuiGrid": {
            "justify": "space-evenly",
            "spacing": 1,
        },
        "MuiPaper": {
            "style": {
                "marginBottom": "1em",
                "marginTop": "1em",
            },
        },
        "MuiTextField": {
            "size": "small",
            "variant": "outlined",
            "margin": "normal",
        },
    },
};

export const theme = createMuiTheme(options);
