import { FormikErrors } from "formik";
import { removeUndefinedProps } from "../services/remove_undefined_props";
import { AccountNumberSubmissionEntry } from "../types/account_number_submission_entry";
import { validateDgid } from "./validate_dgid";

export const validateAccountNumberSubmissionEntry
    = async (entry: AccountNumberSubmissionEntry): Promise<FormikErrors<AccountNumberSubmissionEntry>> =>
        removeUndefinedProps({
            "id": await validateDgid(entry.id),
        });
