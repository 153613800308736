import { FormLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";

export type Option<T> = {
    "text": string;
    "value": T;
}

export type DropdownProps<T, N extends keyof T = keyof T, U extends Option<T[N]> = Option<T[N]>> = {
    "autoComplete"?: boolean;
    "data-testid": string;
    "error"?: string;
    "fullWidth"?: boolean;
    "label": string;
    "name": N;
    "options": U[];
    "placeholder": string;
    "required"?: boolean;
    "setFieldValue": (field: N, value?: T[N]) => void;
    "value"?: U;
};

export function Dropdown<T, N extends keyof T = keyof T, U extends Option<T[N]> = Option<T[N]>>({
    autoComplete = true,
    "data-testid": dataTestId,
    error,
    fullWidth = true,
    label,
    name,
    options,
    placeholder,
    required = false,
    setFieldValue,
    value,
}: DropdownProps<T, N, U>): JSX.Element {
    const handleDropdownChange = (field: N) =>
        (_: any, option: U | null) => setFieldValue(field, option?.value);

    return (
        <Autocomplete
            autoHighlight={true}
            autoSelect={true}
            id={name.toString()}
            getOptionLabel={(option) => option.text}
            onChange={handleDropdownChange(name)}
            options={options}
            placeholder={placeholder}
            renderInput={(params) => <TextField
                {...params}
                error={Boolean(error)}
                fullWidth={fullWidth}
                helperText={error}
                inputProps={{
                    ...params.inputProps,
                    ...autoComplete ? {} : {"autoComplete": "new-password"}, // disable autocomplete and autofill
                    "data-testid": dataTestId,
                }}
                label={<FormLabel required={required}>{label}</FormLabel>}
                name={name.toString()}
            />}
            value={value}
        />
    );
};
