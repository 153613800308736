import { Grid, Hidden, Typography } from "@material-ui/core";
import React, { FC, Fragment } from "react";
import { Image } from "../../../components/image";
import journalCover from "./assets/Cv1_vPA_CA_0520_pms.jpg";

export const LandingPageIntro: FC = () => (<Fragment>
    <Typography component={"div"}>
        <p>
            <strong><em>{"The Clinical Advisor"}</em>{" provides practical advice for practitioners!"}</strong>
        </p>
    </Typography>
    <Grid container={true}>
        <Hidden only="xs">
            <Grid item={true} sm={4} style={{ "padding": "4px" }} >
                <Image alt="Journal Cover" src={journalCover} />
            </Grid>
        </Hidden>
        <Grid item={true} sm={8} xs={12} style={{ "padding": "4px" }}>
            <Typography component={"div"}>
                <p id="clinical_advise_0">
                    {"If you are a Nurse Practitioner or a Physician Assistant, "}
                    <Typography style={{ "color": "#2196f3" }} display="inline" component={"span"}>
                        <strong>{"you can receive or continue to receive "}
                            <em>{"The Clinical Advisor"}</em>{" for free! "}</strong>
                    </Typography>
                    {"Simply fill out the form below to receive (or ensure continued receipt of) a free subscription."}
                </p>
                <p id="clinical_advise_1">
                    {"Nurse Practitioners and Physician Assistants depend on The Clinical Advisor "
                        + "for the latest information on diagnosing, treating, managing, and preventing medical "
                        + "conditions typically seen in the office-based primary-care setting."}
                </p>
                <p id="clinical_advise_2">
                    {"Confirm today and receive Clinical Advisor in print and access online references "
                        + "that help you improve your skills and patient outcomes."}
                </p>
            </Typography>
        </Grid>
    </Grid>
</Fragment>);
