import { Button, Container, Grid, Paper } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import React from "react";
import { useManualAccountForm } from "../services/hooks/use_manual_account_form";
import { states } from "../services/options/states";
import { ManualSubmission } from "../types/subscription/manual_submission";
import { Dropdown } from "./dropdown";
import { TextBox } from "./text_box";

export type ManualAccountFormProps = {
    "prompt"?: JSX.Element | string;
} & Parameters<typeof useManualAccountForm>[0];

export const ManualAccountForm: React.FC<ManualAccountFormProps> = ({
    children,
    prompt = "Complete the following fields in order to confirm your new subscription information.",
    ...useManualAccountFormOptions
}) => {
    const {
        errors,
        handleChange,
        handleSubmit,
        practitionerTypes,
        professionalDetail,
        setFieldValue,
        values,
    } = useManualAccountForm(useManualAccountFormOptions);

    const pratitionerTypeOptions = practitionerTypes.map((type) => ({ "text": type, "value": type }));
    const professionalDetailOptions = (professionalDetail?.values || [])
        .map((type) => ({ "text": type, "value": type }));

    return (<Paper>
        <Container>
            <form onSubmit={handleSubmit} data-testid="formManual">
                <Grid container={true}>
                    <Grid item={true} xs={12} data-testid="manualPrompt">
                        {prompt}
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <TextBox<ManualSubmission>
                            data-testid="firstName"
                            error={errors.firstName}
                            label="First Name"
                            name="firstName"
                            onChange={handleChange}
                            placeholder="First Name"
                            required={true}
                            value={values.firstName}
                        />
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <TextBox<ManualSubmission>
                            data-testid="lastName"
                            error={errors.lastName}
                            label="Last Name"
                            name="lastName"
                            onChange={handleChange}
                            placeholder="Last Name"
                            required={true}
                            value={values.lastName}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <TextBox<ManualSubmission>
                            data-testid="company"
                            error={errors.company}
                            label="Employer/Practice"
                            name="company"
                            onChange={handleChange}
                            placeholder="Employer/Practice"
                            value={values.company}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <TextBox<ManualSubmission>
                            data-testid="street1"
                            error={errors.street1}
                            label="Mailing Address"
                            name="street1"
                            onChange={handleChange}
                            placeholder="Mailing Address"
                            required={true}
                            value={values.street1}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <TextBox<ManualSubmission>
                            data-testid="street2"
                            error={errors.street2}
                            label="Address 2"
                            name="street2"
                            onChange={handleChange}
                            placeholder="Address 2"
                            value={values.street2}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <TextBox<ManualSubmission>
                            data-testid="city"
                            error={errors.city}
                            label="City"
                            name="city"
                            onChange={handleChange}
                            placeholder="City"
                            required={true}
                            value={values.city}
                        />
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Dropdown<ManualSubmission>
                            data-testid="state"
                            error={errors.state}
                            label="State"
                            name="state"
                            options={states}
                            placeholder="Select a State"
                            required={true}
                            setFieldValue={setFieldValue}
                            value={states.find(({ value }) => value === values.state)}
                        />
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <TextBox<ManualSubmission>
                            data-testid="postalCode"
                            error={errors.postalCode}
                            label="Zip Code"
                            name="postalCode"
                            onChange={handleChange}
                            placeholder="Zip Code"
                            required={true}
                            value={values.postalCode}
                        />
                    </Grid>
                    <Grid item={true} xs={12} sm={professionalDetail ? 6 : 12}>
                        <Dropdown<ManualSubmission>
                            data-testid="practitionerType"
                            error={errors.practitionerType}
                            label="Practitioner Type"
                            name="practitionerType"
                            options={pratitionerTypeOptions}
                            placeholder="Select a Practitioner Type"
                            required={true}
                            setFieldValue={setFieldValue}
                            value={{
                                "text": values.practitionerType || "",
                                "value": values.practitionerType,
                            }}
                        />
                    </Grid>
                    {professionalDetail
                        && <Grid item={true} xs={12} sm={6}>
                            <Dropdown<ManualSubmission>
                                data-testid={professionalDetail.type}
                                error={errors[professionalDetail.type]}
                                label={professionalDetail.label}
                                name={professionalDetail.type}
                                options={professionalDetailOptions}
                                placeholder={professionalDetail.label}
                                required={true}
                                setFieldValue={setFieldValue}
                                value={{
                                    "text": values[professionalDetail.type] || "",
                                    "value": values[professionalDetail.type],
                                }}
                            />
                        </Grid>
                    }
                    <Grid item={true} xs={12} sm={6}>
                        <TextBox<ManualSubmission>
                            data-testid="phone"
                            error={errors.phone}
                            label="Phone"
                            name="phone"
                            onChange={handleChange}
                            placeholder="Phone Number"
                            value={values.phone}
                        />
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <TextBox<ManualSubmission>
                            data-testid="npiNumber"
                            error={errors.npiNumber}
                            label="NPI Number"
                            name="npiNumber"
                            onChange={handleChange}
                            placeholder="NPI Number"
                            value={values.npiNumber}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Button
                            data-testid="submitManual"
                            endIcon={<SendIcon />}
                            type="submit">
                            {"Submit"}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    </Paper >);
};
