import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

type BackButtonProps = {
    "name": string;
    "text"?: string;
    "variant"?: "text" | "outlined" | "contained";
}

export const BackButton: React.FC<BackButtonProps> = ({ name, text = "Cancel", variant = "contained" }) => {
    const history = useHistory();
    return (
        <Button
            data-testid={`cancel${name}`}
            onClick={() => history.goBack()}
            startIcon={<CancelIcon />}
            variant={variant}>
            {text}
        </Button>
    );
};
