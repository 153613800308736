import { Button, Grid } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import React, { FC, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { ClientCustomizations } from "../context/client_customizations";

export type AlternateSubscriptionMethodProps = {
    "buttonText": string;
    "label": string;
    "linkTo": string;
    "name": string;
}

export const AlternateSubscriptionMethod: FC<AlternateSubscriptionMethodProps> = ({
    buttonText,
    label,
    linkTo,
    name,
}) => {
    const { "alternateSubscriptionButtonAlignment": buttonAlignment } = useContext(ClientCustomizations);
    return (
        <Fragment>
            <Grid item={true} xs={12} data-testid={`${name}Label`}>
                {label}
            </Grid>
            <Grid item={true} xs={12} >
                <Button
                    component={Link}
                    to={linkTo}
                    data-testid={`${name}Button`}
                    endIcon={<SendIcon />}
                    style={{ "float": buttonAlignment }}>
                    {buttonText}
                </Button>
            </Grid>
        </Fragment>
    );
};
