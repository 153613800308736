export type NonBlank<T> = T extends "" ? never : T;

export type NonBlankProps<T> = {
    [P in keyof T]: NonBlank<T[P]>;
};

export const removeBlankStringProps = <T>(source: T): NonBlankProps<T> => {
    return Object.entries(source).filter(([, value]) => value !== "")
        .reduce((acc, [key, value]) => {
            acc[key as keyof T] = typeof value === "object" ? removeBlankStringProps(value) : value;
            return acc;
        }, {} as NonBlankProps<T>);
};
