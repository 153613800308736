import { removeUndefinedProps } from "../services/remove_undefined_props";
import { PhysicianSearchData } from "../types/requests/physician_search_data";
import { FormikErrors } from "formik";

export const validatePhysicianSearch
    = async (entry: PhysicianSearchData): Promise<FormikErrors<PhysicianSearchData>> =>
        removeUndefinedProps({
            "firstName": !entry.firstName
                ? "Please provide your first name"
                : undefined,
            "lastName": !entry.lastName
                ? "Please provide your last name"
                : undefined,
            "state": !entry.state
                ? "Please select the state in which you live"
                : undefined,
            "gradYear": !entry.gradYear
                ? "Please select the year in which you graduated"
                : undefined,
            "birthYear": !entry.birthYear
                ? "Please select the year that you were born"
                : undefined,
        });
