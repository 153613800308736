import { MockAccount } from "../../types/mock_account";
import { AccountHcpData } from "../../types/requests/account_hcp_data";
import { getAccount } from "./get_account";

const searchById = (mockAccounts: MockAccount[], targetId: string) => {
    return mockAccounts.find(({ dgid }) => dgid === targetId);
};

const searchByHcpData = (mockAccounts: MockAccount[], data: AccountHcpData) => {
    return mockAccounts.find((mockAccount) => {
        const isPhysician = Boolean(mockAccount["scrambledMe" as keyof AccountHcpData]);
        const entries = Object.entries(data)
            .filter(([key, value]) => value || (isPhysician && key === "npiNumber"));
        return entries
            .filter(([key, value]) => mockAccount[key as keyof AccountHcpData]?.toLowerCase() === value?.toLowerCase())
            .length === entries.length;
    });
};

export function getAccountWithMockAccounts(mockAccounts: MockAccount[]) {
    return async (accountId: string | AccountHcpData) => {
        const search = typeof accountId === "string"
            ? searchById(mockAccounts, accountId)
            : searchByHcpData(mockAccounts, accountId);
        return search || await getAccount(accountId);
    };
}

