import { Grid } from "@material-ui/core";
import React, { ReactElement } from "react";


export type LabeledTextRowProps = {
    "testId": string;
    "title": ReactElement | string | null;
    "value": ReactElement | string | null;
}

export const LabeledTextRow: React.FC<LabeledTextRowProps> = ({ testId, title, value }) => (
    <Grid container={true} item={true} xs={12} style={{ "paddingTop": "0.25rem", "paddingBottom": "0.25rem" }}>
        <Grid item={true} xs={6} data-testid={`${testId}Title`} style={{ "textAlign": "right" }}>
            <strong>{title}</strong>
        </Grid>
        <Grid item={true} xs={6} data-testid={`${testId}Value`}>
            {value}
        </Grid>
    </Grid>
);
