import { MockAccount } from "../types/mock_account";

export const demoAccounts: MockAccount[] = [
    {
        "address1": "3940 Hilltop St",
        "address2": "",
        "birthYear": "1962",
        "city": "Springfield",
        "dgid": "0132476554",
        "firstName": "William",
        "formattedName": "Dr. William J Sinclair",
        "gradYear": "1988",
        "lastName": "Sinclair",
        "isMockAccount": true,
        "npiNumber": "physician",
        "scrambledMe": "physician",
        "state": "MA",
        "zip": "01103",
    },
    {
        "address1": "1567 Ashford Drive",
        "address2": "",
        "city": "Luray",
        "dgid": "1757699087",
        "firstName": "Caroline",
        "formattedName": "Caroline Jordan",
        "lastName": "Jordan",
        "isMockAccount": true,
        "npiNumber": "1757699087",
        "state": "KS",
        "zip": "67649",
    },
    {
        "address1": "3504 Essex Court",
        "address2": "",
        "birthYear": "1952",
        "city": "White River Junction",
        "dgid": "3564475112",
        "firstName": "Tracy",
        "formattedName": "Dr. Tracy Stolz",
        "gradYear": "1977",
        "lastName": "Stolz",
        "isMockAccount": true,
        "npiNumber": "physician",
        "scrambledMe": "physician",
        "state": "VT",
        "zip": "05001",
    },
];
