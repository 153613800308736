import { Container } from "@material-ui/core";
import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { App } from "../../../App";
import { DefaultFooter } from "../../../components/default_footer";
import { Image } from "../../../components/image";
import { useRoutes } from "../../../services/hooks/use_routes";
import { theme } from "../../../themes/alternate";
import renewHeader from "./assets/Sub_Page_CA_header_sub_renew.jpg";
import thankYou from "./assets/Sub_Page_CA_header_thankyou.jpg";
import { LandingPageIntro } from "./landing_page_intro";

export const ClinicalAdvisor: React.FC = () => {
    const routes = useRoutes();
    return (<Fragment>
        <Container style={{ "padding": "0px" }}>
            <Switch>
                <Route path={routes.confirmationPage}>
                    <Image alt="Thank You" src={thankYou} />
                </Route>
                <Route path={routes.appLookup}>
                    <Image alt={"Renew Header"} src={renewHeader} />
                    <div
                        data-testid="landingPageIntro"
                        style={{ "maxWidth": "755px", "margin": "auto" }}
                    >
                        <LandingPageIntro />
                    </div>
                </Route>
                <Route>
                    <Image alt={"Renew Header"} src={renewHeader} />
                </Route>
            </Switch>
        </Container>
        <Container style={{ "maxWidth": "800px" }}>
            <App
                alternateSubscriptionButtonAlignment="left"
                bookId="172"
                htmlTitle="Clinical Advisor"
                landingPageIntro={LandingPageIntro}
                theme={theme}
                title="Clinical Advisor"
                renewConfirmLabel="YES! Renew/Continue my Free Subscription to"
                appSearchLabel="Please enter the following information to renew/confirm your subscription."
                defaultEffortCode="WEB2021"
                effortField={false}
            />
            <DefaultFooter
                copyrightInfo="Copyright © 2020 Haymarket Media, Inc. All Rights Reserved"
                emailAddress="custserv@haymarketmedia.com"
                mailingAddress="Haymarket Media: 275 7th Ave, New York, NY 10001"
                privacyPolicyUrl="https://www.haymarketmediaus.com/haymarket-media-medical-privacy-policy/"
                termsConditionsUrl="https://www.haymarket.com/terms-and-conditions/"
            />
        </Container>
    </Fragment>);
};
