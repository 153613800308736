// provided by https://dmdconnects.atlassian.net/browse/PSM-228

export const pharmacistSiteTypes = [
    "Clinic",
    "Compounding",
    "Department Store",
    "Grocery",
    "Home Health",
    "Hospital",
    "Independent",
    "Institutional",
    "Intravenous",
    "Mail Order",
    "Nursing Home",
    "Retail",
    "Other",
];
