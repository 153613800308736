import React from "react";

export const DefaultPhysicianHeaderLabel = () => {
    return (
        <div>
            {"Please enter the following information to retrieve your "}
            <strong>{"Account Number"}</strong>
            {":"}
        </div>
    );
};
