import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Account } from "../../context/account";
import { Book } from "../../context/book";
import { Effort } from "../../context/effort";
import { SubscriptionSecurityResponse } from "../../types/subscription/subscription_security_response";
import { SubscriptionSurveyResponse } from "../../types/subscription/subscription_survey_response";
import { validateSubscriptionRequestDetails as validate } from "../../validation/validate_subscription_request_details";
import { postSubscription } from "../subscription/post_subscription";
import { useForm } from "./use_form";
import { useRoutes } from "./use_routes";
import { useSurveyQuestions } from "./use_survey_questions";
import { ClientCustomizations } from "../../context/client_customizations";

export type SubscriptionRequestDetails = {
    "doSubscribe": boolean;
    "email": string;
    "emailAgain": string;
    "security": SubscriptionSecurityResponse;
    "survey": SubscriptionSurveyResponse[];
    "token": string;
};

export function useSubscriptionRequestForm() {
    const { accountMatch, accountInfo, changeOfAddress } = useContext(Account);
    const { defaultEffortCode } = useContext(ClientCustomizations);
    const { book } = useContext(Book);
    const { effort } = useContext(Effort);
    const surveyQuestions = useSurveyQuestions();
    const routes = useRoutes();
    const history = useHistory();
    const [submissionError, setError] = useState("");

    const { log } = console;
    const submit = accountInfo.isMockAccount
        ? log as typeof postSubscription
        : postSubscription;
    const onSubmit = async ({ emailAgain, security, ...data }: SubscriptionRequestDetails) => {
        try {
            setError("");
            await submit({
                ...data,
                ...!security.question ? {} : { "security": { ...security } },
                accountMatch,
                "bookId": book.id,
                changeOfAddress,
                "clientId": book.clientId,
                "effort": effort ||  defaultEffortCode ,
            });
            history.replace(routes.confirmationPage);
        } catch (err) {
            setError(err.toString());
        }
    };
    const initialValues: SubscriptionRequestDetails = {
        "doSubscribe": true,
        "email": "",
        "emailAgain": "",
        "security": {
            "question": book.securityQuestion || "",
            "answer": "",
        },
        "survey": surveyQuestions.map(({ label }) => ({
            "question": label || "",
            "answer": "",
            "isOther": false,
        })),
        "token": "",
    };

    const form = useForm({
        initialValues,
        onSubmit,
        validate,
    });
    useEffect(() => {
        form.setFieldValue("security.question", book.securityQuestion);
    }, [book.securityQuestion]);
    return { submissionError, ...form };
}
