import { PractitionerType } from "../../types/subscription/practitioner_type";
import { ProfessionType } from "../../types/subscription/profession_type";

export const practitionerTypeDetailMap: { [K in PractitionerType]: ProfessionType } = {
    "Physician (MD/DO)": "physician",
    "Nurse Practitioner (NP)": "app",
    "Physician’s Assistant (PA)": "app",
    "Certified Nurse Midwife (CNM)": "app",
    "Certified Registered Nurse Anesthetist (CRNA)": "app",
    "Clinical Nurse Specialist (CNS)": "app",
    "Nurse": "other",
    "Pharmacist": "pharmacist",
    "Dentist": "other",
    "Optometrist": "other",
};
