import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Account } from "../../context/account";
import { Book } from "../../context/book";
import { Effort } from "../../context/effort";
import { useQueryParams } from "./use_query_params";
import { useRoutes } from "./use_routes";

export function useLandingPageRedirect() {
    const { bookLoaded } = useContext(Book);
    const { id, setAccountById } = useContext(Account);
    const { setEffort } = useContext(Effort);
    const routes = useRoutes();
    const params = useQueryParams();
    const history = useHistory();
    const [dgidValidationRequestMade, setDgidValidationRequestMade] = useState(false);
    const [pageRedirected, setPageRedirected] = useState(false);

    useEffect(() => {
        const { dgid, effort, page } = params;
        if (page === "accountRenewal" && !dgidValidationRequestMade && dgid) {
            setDgidValidationRequestMade(true);
            setAccountById(dgid);
        }
        if (!pageRedirected) {
            if (effort) {
                setEffort(effort);
            }
            if (page === "accountRenewal"
                ? Boolean(id)
                : (bookLoaded && routes[page as keyof typeof routes])
            ) {
                setPageRedirected(true);
                history.push(routes[page as keyof typeof routes]);
            }
        }
    }, [
        bookLoaded,
        dgidValidationRequestMade,
        history,
        id,
        pageRedirected,
        params,
        routes,
        setAccountById,
        setEffort,
    ]);
}
