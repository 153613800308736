import { AppSearchData } from "../../types/requests/app_search_data";
import { validateAppSearch as validate } from "../../validation/validate_app_search";
import { useSearchForm } from "./use_search_form";

export function useAppSearchForm({
    initialValues = {
        "firstName": "",
        "npiNumber": "",
        "lastName": "",
        "state": "",
    },
}: {
    "initialValues"?: AppSearchData;
} = {}) {
    return useSearchForm({ initialValues, validate });
}
