import React from "react";
import { Loading } from "../components/loading";
import { PasswordDialog } from "../components/password_dialog";
import { useUnsafePassword } from "../services/hooks/use_unsafe_password";

type RequireUnsafePasswordProps = {
    "passwords"?: string[];
    "salt"?: string;
}

export const RequireUnsafePassword: React.FC<RequireUnsafePasswordProps> = ({
    children,
    passwords,
    salt,
}) => {
    const { isAuthenticated, error, onSubmit } = useUnsafePassword({ passwords, salt });
    return (
        <Loading loading={isAuthenticated === undefined}>
            {isAuthenticated
                ? children
                : <PasswordDialog onSubmit={onSubmit} error={error} />}
        </Loading>
    );
};
