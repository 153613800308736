import React, { Fragment } from "react";
import { PhysicianSearchForm } from "../components/physician_search_form";
import { BackButton } from "../components/back_button";

export const PhysicianLookup: React.FC = () => {
    return (
        <Fragment>
            <PhysicianSearchForm />
            <BackButton name="PhysicianLookup" />
        </Fragment>
    );
};
