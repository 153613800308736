import { Paper, Typography } from "@material-ui/core";
import React from "react";

export type MessageProps = {
    "content": string;
    "data-testid": string;
    "error"?: boolean;
    "header": string;
}
const baseStyle = {
    "padding": "0.5em",
};

export const Message: React.FC<MessageProps> = ({ content, "data-testid": dataTestId, error, header }) => (
    <Paper data-testid={dataTestId} style={error ? { ...baseStyle, "color": "red" } : baseStyle}>
        <Typography variant="subtitle1" gutterBottom={true} className="header">{header}</Typography>
        <Typography variant="subtitle2" gutterBottom={true}>{content}</Typography>
    </Paper>
);
