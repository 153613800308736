import React, { useEffect } from "react";
import { ENV } from "../../config/environment";
import { getHost } from "./medtarget_system_host";

export const ReaderSnippetCode: React.FC<{ environment: ENV }> = ({ environment }) => {
  useEffect(() => {
    const { orgId, domain, apiKey } = getHost(environment);
    const aimVariable = `AIM_${orgId}`;
    const envAttribute = environment === "prod"
      ? ""
      : `n.setAttribute("data-sub",'${environment}');`;
    const script = document.createElement("script");
    script.innerHTML = `(function(w,d,s,m,n,t){
        w[m]=w[m]||{init:function(){(w[m].q=w[m].q||[]).push(arguments);},ready:function(c){if('function'!=typeof c)
        {return;}(w[m].c=w[m].c||[]).push(c);c=w[m].c;
        n.onload=n.onreadystatechange=function(){if(!n.readyState||/loaded|complete/.test(n.readyState))
            {n.onload=n.onreadystatechange=null;
        if(t.parentNode&&n.parentNode){t.parentNode.removeChild(n);}while(c.length)
        {(c.shift())();}}};}},w[m].d=1*new Date();n=d.createElement(s);
        t=d.getElementsByTagName(s)[0];
        n.async=1;n.src='${domain}/javascript/beacon.js?'+Date.now().toString();${envAttribute}n.
        setAttribute("data-aim",m);t.parentNode.insertBefore(n,t);
    })(window,document,'script','${aimVariable}');    
    ${aimVariable}.init('${apiKey}');`;
    document.body.appendChild(script);
  }, [environment]);
  return null;
};



