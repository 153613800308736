import { Button, Grid } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Book } from "../context/book";
import { useRoutes } from "../services/hooks/use_routes";

type ManualFallbackDialogProps = {
    "text"?: string;
    "variant"?: "text" | "outlined" | "contained";
}

export const ManualFallbackDialog: React.FC<ManualFallbackDialogProps> = ({
    text = "Manual Sign-Up",
    variant = "contained",
}) => {
    const { permitManual } = useContext(Book);
    const history = useHistory();
    const routes = useRoutes();

    return permitManual
        ? (
            <Grid item={true} xs={12} data-testid={`manualFallbackPrompt`}>
                {"If your account cannot be found, please proceed to Manual Sign-Up"}
                <Button
                    data-testid={`manualSignup`}
                    onClick={() => history.push(routes.manualSignUp)}
                    startIcon={<SendIcon />}
                    variant={variant}
                >
                    {text}
                </Button>
            </Grid>
        )
        : null;
};
