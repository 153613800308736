import { Grid } from "@material-ui/core";
import React from "react";
import { useRoutes } from "../services/hooks/use_routes";
import { SignupPermissions } from "../types/signup_permissions";
import { AlternateSubscriptionMethod } from "./alternate_subscription_method";

export const AlternateSubscriptionMethods: React.FC<SignupPermissions> = ({
    permitApps,
    permitManual,
    permitPhysicians,
}) => {
    const routes = useRoutes();
    const physicianLookupText = "If you are unable to locate your Account Number, "
        + "use the Physician Lookup button to retrieve your information.";
    const manualSignUpText = `If you are a new subscriber, please proceed with our Manual Sign-up.`;
    return ((permitApps || permitManual || permitPhysicians)
        && <Grid container={true}>
            {permitPhysicians && <AlternateSubscriptionMethod
                buttonText="Physician Look-up"
                label={physicianLookupText}
                linkTo={routes.physicianLookup}
                name="physicianLookup"
            />}
            {permitApps && <AlternateSubscriptionMethod
                buttonText="APP Look-up"
                label="Advanced Practice Providers (APP's)"
                linkTo={routes.appLookup}
                name="appLookup"
            />}
            {permitManual && <AlternateSubscriptionMethod
                buttonText="Manual Sign-up"
                label={manualSignUpText}
                linkTo={routes.manualSignUp}
                name="manualSignUp"
            />}
        </Grid>
    ) as JSX.Element;
};
