import React from "react";
import { Router } from "./containers/router";
import { WithContext } from "./containers/with_context";
import { TemplateAssets } from "./types/template_assets";

export const App: React.FC<TemplateAssets> = (props) => {
    return (
        <WithContext {...props}>
            <Router />
        </WithContext>
    );
};
