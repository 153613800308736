import { Container, Grid, Paper } from "@material-ui/core";
import React, { useContext } from "react";
import { Book } from "../context/book";
import { ClientCustomizations } from "../context/client_customizations";

export const ConfirmationPage: React.FC = () => {
    const customTitle = useContext(ClientCustomizations).title;
    const bookTitle = useContext(Book).book.title;
    const title = customTitle || bookTitle;
    return (
        <Paper>
            <Container>
                <Grid container={true}>
                    <Grid item={true} xs={12} data-testid="confirmationMessage">
                        {"Thank you for submitting your request to "}<strong>{title}</strong>
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    );
};
