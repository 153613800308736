import { Container } from "@material-ui/core";
import React, { Fragment } from "react";
import { App } from "../../../App";
import { DefaultFooter } from "../../../components/default_footer";
import { RequireUnsafePassword } from "../../../containers/require_unsafe_password";
import { demoAccounts } from "../../../mocks/demo_accounts";
import { getAccountWithMockAccounts } from "../../../services/account/get_account_with_mock_accounts";
import { theme } from "../../../themes/alternate";
import journalCover from "./assets/homepage_banner.png";
import mailingLabel from "./assets/label.png";

export const DemoOne: React.FC = () => {
    const landingPageIntro = () => (<Fragment>
        <h1>
            {`Sample Heading`}
        </h1>
        <h2>
            {`Sample landing page sub text`}
        </h2>
    </Fragment>);
    return (<RequireUnsafePassword>
        <Container style={{ "padding": "0px" }}>
            <img src={journalCover} alt={"Renew Header"} style={{ "maxWidth": "100%" }} />
        </Container>
        <Container style={{ "maxWidth": "700px" }}>
            <App
                alternateSubscriptionButtonAlignment="left"
                bookId="191"
                getAccount={getAccountWithMockAccounts(demoAccounts)}
                htmlTitle="Demo 1"
                landingPageIntro={landingPageIntro}
                mailingLabel={mailingLabel}
                theme={theme}
            />
            <DefaultFooter
                copyrightInfo="Copyright 2020 Medical Publisher Inc."
                emailAddress="support@medicalpublisher.com"
                mailingAddress="Office of the Publisher: 100 Maple Rd, Ste 900, Anytown, OH  21345 USA"
                privacyPolicyUrl="https://hcn.health/privacy-policy/"
            />
        </Container>
    </RequireUnsafePassword>);
};
