import qs from "qs";

export const appendQueryParams = <T>(url: string, params: T) => {
    const [, path, search] = url.match(/^([^?]*)\??([\w\W]*)$/) as string[];
    const query = {
        ...qs.parse(search),
        ...params,
    };
    return `${path}?${qs.stringify(query)}`;
};
