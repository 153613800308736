import { apiKey } from "../../config/api_key";
import { apiRoutes } from "../../config/api_routes";
import { AccountResponse } from "../../types/account_response";
import { PublicationsAccountGetQueryParams } from "../../types/query_params/publications_account_get_query_params";
import { AccountHcpData } from "../../types/requests/account_hcp_data";
import { apiGet } from "../http/api_get";

const searchById = (id: string, bookId: string) => {
    const params: { id: string; "client-override"?: string } = { id };
    if (bookId === "172") {
        params["client-override"] = "sme-acs";
    }
    return apiGet<PublicationsAccountGetQueryParams, AccountResponse>({
        "headers": { "x-api-key": apiKey },
        "params": { ...params },
        "url": apiRoutes.getAccount,
    });
};

const searchByHcpData = (data: AccountHcpData) => apiGet<PublicationsAccountGetQueryParams, AccountResponse>({
    "headers": { "x-api-key": apiKey },
    "params": data,
    "url": apiRoutes.getAccount,
});

export function getAccount(accountId: string | AccountHcpData, bookId?: string) {
    const search = typeof accountId === "string"
        ? searchById(accountId, bookId ? bookId : "")
        : searchByHcpData(accountId);
    return search.then(({ data }) => data);
}
