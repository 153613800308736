import { useContext } from "react";
import { Account } from "../../context/account";
import { Book } from "../../context/book";

export function useSurveyQuestions() {
    const { book } = useContext(Book);
    const { accountInfo } = useContext(Account);

    const surveyQuestions = accountInfo.isPhysician
        ? []
        : book.questions || [];
    return surveyQuestions;
}
