import { PhysicianSearchData } from "../../types/requests/physician_search_data";
import { validatePhysicianSearch as validate } from "../../validation/validate_physician_search";
import { useSearchForm } from "./use_search_form";

export function usePhysicianSearchForm({
    initialValues = {
        "birthYear": "",
        "firstName": "",
        "gradYear": "",
        "lastName": "",
        "state": "",
    },
}: {
    "initialValues"?: PhysicianSearchData;
} = {}) {
    return useSearchForm({ initialValues, validate });
}
