import { Backdrop, CircularProgress } from "@material-ui/core";
import React, { Fragment } from "react";

export const Loading: React.FC<{ loading: boolean }> = ({ children, loading }) => (
    loading
        ? <Backdrop open={loading} style={{ "zIndex": 1600 }} >
            <CircularProgress color="inherit" />
        </Backdrop >
        : <Fragment>{children}</Fragment>
);
