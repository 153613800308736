import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { render } from "react-snapshot";
import { TemplateRoute } from "./containers/template_route";
import "./index.css";
import "./polyfills";
import * as serviceWorker from "./serviceWorker";
import { DemoOne } from "./templates/demo/one/demo_one";
import { ClinicalAdvisor } from "./templates/haymarket/ca/clinical_advisor";
import { MonthlyPrescribingReferenceRedirect } from "./templates/haymarket/mpr/redirect_page";
import { JOCP } from "./templates/ppp/jocp/JOCP";

render(
    <BrowserRouter>
        <Switch>
            <TemplateRoute path="/demo/one/" component={DemoOne} />
            <TemplateRoute path="/haymarket/ca/" component={ClinicalAdvisor} />
            <TemplateRoute path="/haymarket/mpr/" component={MonthlyPrescribingReferenceRedirect} />
            <TemplateRoute path="/ppp/jocp/" component={JOCP} />
        </Switch>
    </BrowserRouter>
    , document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
