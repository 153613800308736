import { useFormik, FormikErrors } from "formik";

export function useForm<T>({
    initialValues,
    onSubmit,
    validate,
    validateOnBlur = false,
    validateOnChange = false,
}: {
    "initialValues": T;
    "onSubmit": (values: T) => void;
    "validate": (entry: T) => Promise<FormikErrors<T>>;
    "validateOnBlur"?: boolean;
    "validateOnChange"?: boolean;
}) {
    return useFormik({
        initialValues,
        onSubmit,
        validate,
        validateOnBlur,
        validateOnChange,
    });
}
