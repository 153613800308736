import React from "react";
import defaultMailingLabel from "../templates/default/label.png";
import { ClientCustomizations as TypeDefinition } from "../types/client_customizations";

export const ClientCustomizations = React.createContext<TypeDefinition>({
    "alternateSubscriptionButtonAlignment": "right",
    "confirmationPage": () => null,
    "landingPageIntro": () => null,
    "mailingLabel": defaultMailingLabel,
    "effortField": true,
    "renewConfirmLabel": "",
    "physicianHeaderLabel": "",
    "appSearchLabel": ""
});
