// DECLARE @table_name VARCHAR(100) = 'AMA-AOA-1PASS'
// DECLARE @field_name VARCHAR(100) = 'Primary Specialty'

// SELECT bbbtm.[Description]
// FROM [LMS_Files].[dbo].[BBB_FileList] AS bbbfl
//     INNER JOIN [LMS_Files].[dbo].[BBB_MainTree] AS bbbmt
//     ON bbbfl.[File_] = bbbmt.[File_]
//     INNER JOIN [LMS_Files].[dbo].[BBB_TreeMap] AS bbbtm
//     ON bbbmt.[TreeMap] = bbbtm.[MapName]
// WHERE bbbfl.[caption] = @table_name
//     AND bbbmt.[Description] = @field_name
//     AND bbbtm.[Type] = 'child'
//     AND bbbtm.[Description] != 'Unspecified (US)'
// ORDER BY bbbtm.[Description] ASC

export const physicianSpecialties = [
    "Abdominal Radiology (AR)",
    "Abdominal Surgery (AS)",
    "Addiction Medicine (ADM)",
    "Addiction Psychiatry (ADP)",
    "Adolescent Medicine (ADL)",
    "Adolescent Medicine (AMF)",
    "Adolescent Medicine (AMI)",
    "Adult Cardiothoracic Anesthesiology (ACA)",
    "Adult Congenital Heart Disease (CHD)",
    "Adult Reconstructive Orthopedics (OAR)",
    "Adv Heart Failure & Transplant Cardiology (AHF)",
    "Advanced Surgical Oncology (ASO)",
    "Aerospace Medicine (AM)",
    "Allergy & Immunology (AI)",
    "Allergy (A)",
    "Anatomic Pathology (ATP)",
    "Anatomic/Clinical Pathology (PTH)",
    "Anesthesiology (AN)",
    "Anesthesiology Critical Care Medicine (ACC)",
    "Blood Banking/Transfusion Medicine (BBK)",
    "Brain Injury Medicine (BIN)",
    "Brain Injury Medicine (BIN)",
    "Brain Injury Medicine (BIP)",
    "Cardiothoracic Radiology (CTR)",
    "Cardiovascular Disease (CD)",
    "Chemical Pathology (PCH)",
    "Child & Adolescent Psychiatry (CHP)",
    "Child Abuse Pediatrics (CAP)",
    "Child Neurology (CHN)",
    "Clinical & Laboratory Immunology (ILI)",
    "Clinical & Laboratory Immunology (PLI)",
    "Clinical Biochemical Genetics (CBG)",
    "Clinical Cardiac Electrophysiology (ICE)",
    "Clinical Cytogenetics (CCG)",
    "Clinical Genetics (CG)",
    "Clinical Informatics (CID)",
    "Clinical Informatics (CIE)",
    "Clinical Informatics (CIF)",
    "Clinical Informatics (CIM)",
    "Clinical Informatics (CIP)",
    "Clinical Informatics (CLI)",
    "Clinical Informatics (IAN)",
    "Clinical Laboratory Immunology (ALI)",
    "Clinical Molecular Genetics (CMG)",
    "Clinical Neurophysiology (CN)",
    "Clinical Pathology (CLP)",
    "Clinical Pharmacology (PA)",
    "Clncl & Lab Dermatological Immunology (DDL)",
    "Colon & Rectal Surgery (CRS)",
    "Congenital Cardiac Surgery (CHS)",
    "Cosmetic Surgery (CS)",
    "Craniofacial Surgery (CFS)",
    "Critical Care Medicine (CCA)",
    "Critical Care Medicine (CCE)",
    "Critical Care Medicine (CCM)",
    "Critical Care Medicine (OCC)",
    "Cytopathology (PCP)",
    "Dermatologic Surgery (DS)",
    "Dermatology (D)",
    "Dermatopathology (DMP)",
    "Dermatopathology (DMP)",
    "Developmental-Behavioral Pediatrics (DBP)",
    "Diabetes (DIA)",
    "Diagnostic Radiology (DR)",
    "Diagnostic Radiology/Nuclear Medicine (DNN)",
    "Emergency Medical Services (EMS)",
    "Emergency Medicine (EM)",
    "Emergency Medicine/Family Medicine (EFM)",
    "Emergency Sports Medicine (ESM)",
    "Endocrinology, Diabetes, Metabolism (END)",
    "Endovascular Surgical Neuroradiology (ENR)",
    "Endovascular Surgical Neuroradiology (ES)",
    "Endovascular Surgical Neuroradiology (ESN)",
    "Epidemiology (EP)",
    "Epilepsy (EPL)",
    "Facial Plastic Surgery (FPS)",
    "Family Medicine (FM)",
    "Family Medicine/Preventive Medicine (FMP)",
    "Family Practice (FP)",
    "Female Pelvic Med & Reconstruct Surg (FPR)",
    "Female Pelvic Med & Reconstruct Surg (UPR)",
    "Foot & Ankle, Orthopedics (OFA)",
    "Forensic Pathology (FOP)",
    "Forensic Psychiatry (PFP)",
    "Gastroenterology (GE)",
    "General Practice (GP)",
    "General Preventive Medicine (GPM)",
    "General Surgery (GS)",
    "Geriatric Medicine (FPG)",
    "Geriatric Medicine (IMG)",
    "Geriatric Psychiatry (PYG)",
    "Gynecological Oncology (GO)",
    "Gynecology (GYN)",
    "Hand Surgery (HS)",
    "Hand Surgery General (HSS)",
    "Hand Surgery Orthopedics (HSO)",
    "Hand Surgery Plastic (HSP)",
    "Head & Neck Surgery (HNS)",
    "Hematology (HEM)",
    "Hematology (HMP)",
    "Hematology/Oncology (HO)",
    "Hepatology (HEP)",
    "Hospice & Palliative Medicine (HPA)",
    "Hospice & Palliative Medicine (HPD)",
    "Hospice & Palliative Medicine (HPE)",
    "Hospice & Palliative Medicine (HPF)",
    "Hospice & Palliative Medicine (HPI)",
    "Hospice & Palliative Medicine (HPM)",
    "Hospice & Palliative Medicine (HPN)",
    "Hospice & Palliative Medicine (HPN)",
    "Hospice & Palliative Medicine (HPO)",
    "Hospice & Palliative Medicine (HPP)",
    "Hospice & Palliative Medicine (HPR)",
    "Hospice & Palliative Medicine (HPS)",
    "Hospitalist (HOS)",
    "IM Family Practice (IFP)",
    "IM Physical Med Rehab (MPM)",
    "Immunology (IG)",
    "Infectious Disease (ID)",
    "Internal Med Emergency Medicine (MEM)",
    "Internal Med Preventive Medicine (IPM)",
    "Internal Medicine (IM)",
    "Internal Medicine Neurology (MN)",
    "Internal Medicine Pediatrics (MPD)",
    "Internal Medicine Psychiatry (MP)",
    "Internal Medicine/Anesthesiology (IMA)",
    "Internal Medicine/Dermatology (IMD)",
    "Internal Medicine/Medical Genetics (MDG)",
    "Internal Medicine/Nuclear Medicine (INM)",
    "Internal/Emergency/Critical Care (IEC)",
    "Interventional Cardiology (IC)",
    "Interventional Radiology - Independent (IRD)",
    "Interventional Radiology - Integrated (IRI)",
    "Legal Medicine (LM)",
    "Maternal & Fetal Medicine (MFM)",
    "Medical Biochemical Genetics (MBG)",
    "Medical Genetics & Genomics/Maternal-Fetal Medicine (MGM)",
    "Medical Genetics (MG)",
    "Medical Management (MDM)",
    "Medical Microbiology (MM)",
    "Medical Oncology (ON)",
    "Medical Physics (MDP)",
    "Medical Toxicology (ETX)",
    "Medical Toxicology (PDT)",
    "Medical Toxicology (PTX)",
    "Molecular Genetic Pathology (MGG)",
    "Molecular Genetic Pathology (MGP)",
    "Musculoskeletal Oncology (OMO)",
    "Musculoskeletal Radiology (MSR)",
    "Neonatal-Perinatal Medicine (NPM)",
    "Nephrology (NEP)",
    "Neurodevelopmental Disabilities (NDN)",
    "Neurodevelopmental Disabilities (NDN)",
    "Neurodevelopmental Disabilities (NDP)",
    "Neurological Surgery (NS)",
    "Neurology (N)",
    "Neurology/Diagnostic Rad/Neuro Rad (NRN)",
    "Neurology/Nuclear Medicine (NNM)",
    "Neurology/Phys Med & Rehabilitation (NPR)",
    "Neuromuscular Medicine (NMN)",
    "Neuromuscular Medicine (NMP)",
    "Neuropathology (NP)",
    "Neuropsychiatry (NUP)",
    "Neuroradiology (RNR)",
    "Neurotology/OTO (NO)",
    "Nuclear Cardiology (NC)",
    "Nuclear Medicine (NM)",
    "Nuclear Radiology (NR)",
    "Nutrition (NTR)",
    "Obstetric Anesthesiology (OAN)",
    "Obstetrics & Gynecology (OBG)",
    "Obstetrics (OBS)",
    "Occupational Medicine (OM)",
    "Ophthalmic Plastic & Reconstructive Surg (OPR)",
    "Ophthalmology (OPH)",
    "Oral & Maxillofacial Surgery (OMF)",
    "Orthopedic Surgery (ORS)",
    "Orthopedic Surgery of the Spine (OSS)",
    "Orthopedic Trauma (OTR)",
    "Osteopathic Manipulative Medicine (OMM)",
    "Osteopathic Neuromusculoskeletal Medicine (ONM)",
    "Other Specialty (OS)",
    "Otolaryngology (OTO)",
    "Pain Management (PME)",
    "Pain Medicine (APM)",
    "Pain Medicine (PMD)",
    "Pain Medicine (PMM)",
    "Pain Medicine Neurology (PMN)",
    "Pain Medicine Phys Medicine & Rehab (PMP)",
    "Pain Medicine Psychiatry (PPN)",
    "Palliative Medicine (PLM)",
    "Pediatric Allergy (PDA)",
    "Pediatric Anesthesiology (PAN)",
    "Pediatric Cardiology (PDC)",
    "Pediatric Cardiothoracic Surgery (PCS)",
    "Pediatric Critical Care Medicine (CCP)",
    "Pediatric Dermatology (PDD)",
    "Pediatric Emergency Medicine (PE)",
    "Pediatric Emergency Medicine (PEM)",
    "Pediatric Endocrinology (PDE)",
    "Pediatric Gastroenterology (PG)",
    "Pediatric Hematology/Oncology (PHO)",
    "Pediatric Infectious Diseases (PDI)",
    "Pediatric Nephrology (PN)",
    "Pediatric Ophthalmology (PO)",
    "Pediatric Orthopedics (OP)",
    "Pediatric Otolaryngology (PDO)",
    "Pediatric Pathology (PP)",
    "Pediatric Pulmonology (PDP)",
    "Pediatric Radiology (PDR)",
    "Pediatric Rehabilitation Medicine (RPM)",
    "Pediatric Rheumatology (PPR)",
    "Pediatric Surgery (NSP)",
    "Pediatric Surgery (PDS)",
    "Pediatric Transplant Hepatology (PTP)",
    "Pediatric Urology (UP)",
    "Pediatric/Psych/Child Adolescent Psych (CPP)",
    "Pediatrics (PD)",
    "Pediatrics/Anesthesiology (PDN)",
    "Pediatrics/Dermatology (PDM)",
    "Pediatrics/Emergency Medicine (EMP)",
    "Pediatrics/Medical Genetics (PMG)",
    "Pediatrics/Phys Med & Rehabilitation (PPM)",
    "Pharmaceutical Medicine (PHM)",
    "Phlebology (PHL)",
    "Physical Medicine & Rehabilitation (PM)",
    "Plastic Surgery (PS)",
    "Plastic Surgery - Integrated (PSI)",
    "Plastic Surgery within the Head & Neck (PSH)",
    "Plastic Surgery within the Head & Neck (PSO)",
    "Plastic Surgery within the Head & Neck (PSP)",
    "Procedural Dermatology (PRD)",
    "Proctology (PRO)",
    "Psychiatry (P)",
    "Psychiatry/Family Medicine (FPP)",
    "Psychiatry/Neurology (PYN)",
    "Psychoanalysis (PYA)",
    "Psychosomatic Medicine (PYM)",
    "Public Health & Gen Preventive Med (PHP)",
    "Pulmonary Critical Care Medicine (PCC)",
    "Pulmonary Disease (PUD)",
    "Radiation Oncology (RO)",
    "Radiological Physics (RP)",
    "Radiology (R)",
    "Regional Anesthesiology & Acute Pain Med (RAA)",
    "Reproductive Endocrinology & Infertility (REN)",
    "Rheumatology (RHU)",
    "Selective Pathology (SP)",
    "Sleep Medicine (SMA)",
    "Sleep Medicine (SME)",
    "Sleep Medicine (SMI)",
    "Sleep Medicine (SMN)",
    "Sleep Medicine (SMN)",
    "Sleep Medicine (SMO)",
    "Sleep Medicine (SMP)",
    "Spinal Cord Injury (SCI)",
    "Sports Medicine (FSM)",
    "Sports Medicine (ISM)",
    "Sports Medicine (OSM)",
    "Sports Medicine Pediatrics (PSM)",
    "Sports Medicine Phys Med & Rehab (PRS)",
    "Surgical Critical Care (CCS)",
    "Surgical Oncology (SO)",
    "Thoracic Surgery (TS)",
    "Thoracic Surgery - Integrated (TSI)",
    "Transitional Year (TY)",
    "Transplant Hepatology (THP)",
    "Transplant Surgery (TTS)",
    "Trauma Surgery (TRS)",
    "Undersea & Hyperbaric Medicine (UM)",
    "Undersea & Hyperbaric Medicine (UME)",
    "Urgent Care Medicine (UCM)",
    "Urology (U)",
    "Vascular & Interventional Radiology (VIR)",
    "Vascular Medicine (VM)",
    "Vascular Neurology (VN)",
    "Vascular Surgery (VS)",
    "Vascular Surgery - Integrated (VSI)",
];
