import { Button, Container, Grid, Hidden, Paper } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import React, { Fragment, useContext } from "react";
import { ClientCustomizations } from "../context/client_customizations";
import { useAccountNumberSubmissionForm } from "../services/hooks/use_account_number_submission_form";
import { AccountNumberSubmissionEntry } from "../types/account_number_submission_entry";
import { LabeledTextBox } from "./labeled_text_box";
import { Loading } from "./loading";

export const AccountNumberSubmissionForm: React.FC = () => {
    const { mailingLabel,effortField } = useContext(ClientCustomizations);

    const {
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
    } = useAccountNumberSubmissionForm();

    return (<Paper>
        <Container>
            <Loading loading={isSubmitting} />
            <Grid container={true}>
                <Hidden mdUp={true}>
                    <Grid item={true} style={{ "maxWidth": "300px" }}>
                        <img
                            alt="mailing label"
                            data-testid="mailingLabel"
                            src={mailingLabel}
                            style={{ "maxWidth": "100%" }}
                        />
                    </Grid>
                </Hidden>
                <Grid container={true} item={true} sm={12} md={6}>
                    <form data-testid="formAccountNumber">
                        <LabeledTextBox<AccountNumberSubmissionEntry>
                            data-testid="accountNumber"
                            error={errors.id}
                            label={<Fragment>
                                {"Enter your "}
                                <strong>
                                    {"Account Number"}
                                </strong>
                                {" as it appears on your mailing label:"}
                            </Fragment>}
                            name="id"
                            onChange={handleChange}
                            placeholder="Account Number"
                            value={values.id}
                        />
                       {effortField && <LabeledTextBox<AccountNumberSubmissionEntry>
                            data-testid="effort"
                            error={errors.effort}
                            label={<Fragment>
                                {"Please provide your "}
                                <strong>
                                    {"Effort Code"}
                                </strong>
                                {" as it appears on your renewal form:"}
                            </Fragment>}
                            name="effort"
                            onChange={handleChange}
                            placeholder="Effort Code"
                            value={values.effort}
                        />}
                        <Button
                            data-testid="submitAccountNumber"
                            endIcon={<SendIcon />}
                            onClick={() => handleSubmit()}>
                            {"Continue Renewal"}
                        </Button>
                    </form>
                </Grid>
                <Hidden smDown={true}>
                    <Grid item={true} md={6}>
                        <img
                            alt="mailing label"
                            data-testid="mailingLabel"
                            src={mailingLabel}
                            style={{ "maxWidth": "100%" }}
                        />
                    </Grid>
                </Hidden>
            </Grid>
        </Container>
    </Paper >
    );
};
