import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Account } from "../../context/account";
import { Effort } from "../../context/effort";
import { AccountNumberSubmissionEntry } from "../../types/account_number_submission_entry";
import {
    validateAccountNumberSubmissionEntry as validate,
} from "../../validation/validate_account_number_submission_entry";
import { useForm } from "./use_form";
import { useQueryParams } from "./use_query_params";
import { useRoutes } from "./use_routes";

export function useAccountNumberSubmissionForm() {
    const params = useQueryParams();
    const { effort, setEffort } = useContext(Effort);
    const { id, setAccountById } = useContext(Account);
    const [submissionError, setError] = useState(undefined as unknown as string);
    const routes = useRoutes();
    const history = useHistory();

    const onSubmit = async (input: AccountNumberSubmissionEntry) => {
        try {
            setError(undefined as unknown as string);
            await setAccountById(input.id);
            setEffort(input.effort);
            history.push(routes.accountRenewal);
        } catch {
            setError("Invalid Account Number - Your Account Number could not be Verified");
        }
    };

    const initialValues = {
        "id": params.dgid || id || "",
        "effort": params.effort || effort || "",
    };

    const form = useForm({
        initialValues,
        onSubmit,
        validate,
    });
    return {
        ...form,
        "errors": {
            ...form.errors,
            "id": form.errors.id || submissionError,
        },
    };
}
