import axios from "axios";
import { appendQueryParams } from "../url/appendQueryParams";

export type ApiGetProps<T> = {
    "headers"?: any;
    "params"?: T;
    "url": string;
};

export function apiGet<T, U>({ url, params = {} as T, headers = {} }: ApiGetProps<T>) {
    return axios.get<U>(appendQueryParams(url, params), { headers });
}
