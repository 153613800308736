import { Grid } from "@material-ui/core";
import React from "react";

export const PhysiciansCoa = () => (
    <Grid item={true} xs={11} data-testid="changeOfAddressValue">
        <em>
            {"If your address information is incorrect, please contact the AMA at 800-262-3211 or the AOA, "
                + "800-621-1173 in order to update your information."}
        </em>
    </Grid>
);
