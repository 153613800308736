export type Req<T> = Required<{
    [P in keyof T]: NonNullable<T[P]>;
}>;

export const removeUndefinedProps = <T>(source: T): Req<T> => {
    return Object.entries(source)
        .filter(([, value]) => value !== undefined && value !== null)
        .reduce((acc, [key, value]) => {
            acc[key as keyof T] = typeof value === "object" ? removeUndefinedProps(value) : value;
            return acc;
        }, {} as Req<T>);
};
