import { useState } from "react";
import { sha256 } from "../hash/sha256";
import { buildSessionStore } from "../session/build_session_store";

export const useUnsafePassword = ({
    errorMsg = "Wrong Password",
    passwordKey = "psm-pw",
    passwords = ["688453c982527a8175c50f8ba7c925f6fcd3bfc1d2c98d569e18dc176a51dada"],
    salt = "dmd's super secret password salt",
    store = buildSessionStore(),
}: {
    "errorMsg"?: string;
    "passwordKey"?: string;
    "passwords"?: string[];
    "salt"?: string;
    "store"?: {
        "get": (key: string) => string | undefined;
        "set": (key: string, value: string) => void;
    };
} = {}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(undefined as boolean | undefined);
    const [error, setError] = useState(undefined as string | undefined);

    const authenticate = (password: string) => sha256(password)
        .then((hash) => passwords.includes(hash))
        .catch(() => false);

    authenticate(`${store.get(passwordKey)}`).then(setIsAuthenticated);

    const onSubmit = ({ "password": input }: { "password": string }) => {
        sha256(input + salt)
            .then((hash) => {
                store.set(passwordKey, hash);
                return authenticate(hash);
            })
            .then((success) => {
                setIsAuthenticated(success);
                setError(success ? undefined : errorMsg);
            })
            .catch(setError);
    };

    return {
        isAuthenticated,
        error,
        onSubmit,
    };
};
