import { Button, Container, Dialog } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import React, { useContext } from "react";
import { ManualAccountForm } from "../components/manual_account_form";
import { Account } from "../context/account";
import { ManualSubmission } from "../types/subscription/manual_submission";

export const ChangeOfAddress: React.FC<{ "onClose": () => void; "open": boolean }> = ({ onClose, open }) => {

    const { accountInfo, changeOfAddress, setChangeOfAddress } = useContext(Account);
    const initialValues = changeOfAddress || {
        "city": accountInfo.city,
        "company": "",
        "firstName": accountInfo.firstName,
        "lastName": accountInfo.lastName,
        "npiNumber": "",
        "phone": "",
        "postalCode": accountInfo.zip,
        "practitionerType": "" as any,
        "siteType": undefined,
        "specialty": undefined,
        "state": accountInfo.state,
        "street1": accountInfo.address1,
        "street2": accountInfo.address2,
    };
    const onSubmit = async (account: ManualSubmission) => {
        setChangeOfAddress(account);
        onClose();
    };
    const onCancel = () => {
        setChangeOfAddress(undefined);
        onClose();
    };

    return (
        <Dialog open={open} onBackdropClick={onCancel}>
            <Container>
                <ManualAccountForm
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    prompt="Complete the following fields in order to confirm your new subscription information."
                />
                <Button
                    data-testid={`cancelChangeOfAddress`}
                    onClick={onCancel}
                    startIcon={<CancelIcon />}
                    variant="contained"
                >
                    {"Cancel"}
                </Button>
            </Container>
        </Dialog>
    );
};
