import { Grid, Paper } from "@material-ui/core";
import React, { useContext } from "react";
import { Account } from "../context/account";
import { Effort } from "../context/effort";
import { AppCoa } from "./change_of_address_prompts/app_coa";
import { PhysiciansCoa } from "./change_of_address_prompts/physicians_coa";
import { LabeledTextRow } from "./labeled_text_row";
import { RenderListWithBreaks } from "./render_list_with_breaks";

export const SubscriberInfoDisplay: React.FC = () => {
    const { accountAddress, accountInfo, accountMatch, id } = useContext(Account);
    const { effort } = useContext(Effort);

    return (
        <Paper>
            <Grid container={true}>
                {id && <LabeledTextRow testId="accountNumber" title="Account Number:" value={id} />}
                {effort && <LabeledTextRow testId="effort" title="Effort:" value={effort} />}
                <LabeledTextRow
                    testId="accountDetails"
                    title="Account Details: "
                    value={RenderListWithBreaks({ "items": accountAddress })}
                />
                {accountMatch.strategy !== "manual" && (
                    accountInfo.isPhysician
                        ? <PhysiciansCoa />
                        : <AppCoa />
                )}
            </Grid>
        </Paper>
    );
};
