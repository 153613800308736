import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Account } from "../context/account";
import { Book } from "../context/book";
import { ClientCustomizations } from "../context/client_customizations";
import { useLandingPageRedirect } from "../services/hooks/use_landing_page_redirect";
import { useRoutes } from "../services/hooks/use_routes";
import { AccountRenewal } from "./account_renewal";
import { AppLookup } from "./app_lookup";
import { LandingPage } from "./landing_page";
import { ManualSignup } from "./manual_signup";
import { PhysicianLookup } from "./physician_lookup";

export const Router: React.FC = () => {
    const { "confirmationPage": ConfirmationPage } = useContext(ClientCustomizations);
    const { permitApps, permitManual, permitPhysicians } = useContext(Book);
    const { hasAccountMatch } = useContext(Account);
    const routes = useRoutes();
    useLandingPageRedirect();

    return (
        <Switch>
            {hasAccountMatch && <Route path={routes.accountRenewal}>
                <AccountRenewal />
            </Route>}
            {permitApps && <Route path={routes.appLookup}>
                <AppLookup />
            </Route>}
            {permitManual && <Route path={routes.manualSignUp}>
                <ManualSignup />
            </Route>}
            {permitPhysicians && <Route path={routes.physicianLookup}>
                <PhysicianLookup />
            </Route>}
            <Route path={routes.confirmationPage}>
                <ConfirmationPage />
            </Route>
            <Route path={routes.landingPage} exact={true}>
                <LandingPage {...{ permitApps, permitManual, permitPhysicians }} />
            </Route>
            <Redirect from="*" to={routes.landingPage} />
        </Switch>
    );
};
