import { PractitionerDetails } from "../../types/subscription/practitioner_details";
import { ProfessionType } from "../../types/subscription/profession_type";
import { pharmacistSiteTypes } from "../options/pharmacist_site_types";
import { appSpecialties } from "../options/specialties/app_specialties";
import { physicianSpecialties } from "../options/specialties/physician_specialties";

export type ProfessionalDetail = {
    "label": string; "type": Exclude<keyof PractitionerDetails, "practitionerType">; "values": string[];
};

export const professionalDetails: { [K in ProfessionType]?: ProfessionalDetail } = {
    "app": { "label": "Specialty", "type": "specialty", "values": appSpecialties },
    "other": undefined,
    "pharmacist": { "label": "Site Type", "type": "siteType", "values": pharmacistSiteTypes },
    "physician": { "label": "Specialty", "type": "specialty", "values": physicianSpecialties },
};
