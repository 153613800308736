import { Link } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { ChangeOfAddress } from "../../containers/change_of_address";
import { LabeledTextRow } from "../labeled_text_row";

export const AppCoa = () => {
    const [open, setOpen] = useState(false);
    return (
        <Fragment>
            <LabeledTextRow
                testId="changeOfAddress"
                title=""
                value={
                    <em>
                        {"If your address information is incorrect, please submit a "}
                        <Link onClick={() => setOpen(true)}>
                            {"change of address form"}
                        </Link>
                    </em>
                }
            />
            <ChangeOfAddress open={open} onClose={() => setOpen(false)} />
        </Fragment>
    );
};
