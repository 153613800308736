import { ThemeProvider } from "@material-ui/core";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ConfirmationPage } from "../components/confirmation_page";
import { DefaultAppSearchLabel } from "../components/default_app_search_label";
import { DefaultPhysicianHeaderLabel } from "../components/default_physician_header_label";
import { Account } from "../context/account";
import { Book } from "../context/book";
import { ClientCustomizations } from "../context/client_customizations";
import { Effort } from "../context/effort";
import { getAccount as defaultGetAccount } from "../services/account/get_account";
import { useAccount } from "../services/hooks/use_account";
import { useBook } from "../services/hooks/use_book";
import { useEffort } from "../services/hooks/use_effort";
import defaultFavicon from "../templates/default/logo192.png";
import defaultMailingLabel from "../templates/default/mailing_label.gif";
import { theme as defaultTheme } from "../themes/default";
import { TemplateAssets } from "../types/template_assets";
import { ReaderSnippetCode } from "./snippets/reader_snippet_code";
import { env } from "../config/environment";

export const WithContext: React.FC<TemplateAssets> = ({
    alternateSubscriptionButtonAlignment = "right",
    bookId,
    children,
    confirmationPage = ConfirmationPage,
    favicon = defaultFavicon,
    getAccount = defaultGetAccount,
    htmlTitle = "Subscribe",
    landingPageIntro = () => null,
    mailingLabel = defaultMailingLabel,
    theme = defaultTheme,
    title,
    renewConfirmLabel = "YES! Renew/Continue my Subscription to",
    physicianHeaderLabel = DefaultPhysicianHeaderLabel(),
    appSearchLabel = DefaultAppSearchLabel(),
    effortField = true,
    defaultEffortCode = "",
}) => (
    <HelmetProvider>
        <Helmet>
            <link rel="icon" href={favicon} />
            <title>{htmlTitle}</title>
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
            />
        </Helmet>
        <ReaderSnippetCode environment={env}></ReaderSnippetCode>
        <ClientCustomizations.Provider value={{
            alternateSubscriptionButtonAlignment,
            confirmationPage,
            landingPageIntro,
            mailingLabel,
            title,
            renewConfirmLabel,
            physicianHeaderLabel,
            appSearchLabel,
            effortField,
            defaultEffortCode
        }}>
            <ThemeProvider theme={theme}>
                <Account.Provider value={useAccount(getAccount, bookId)}>
                    <Effort.Provider value={useEffort()}>
                        <Book.Provider value={useBook(bookId)}>
                            {children}
                        </Book.Provider>
                    </Effort.Provider>
                </Account.Provider>
            </ThemeProvider>
        </ClientCustomizations.Provider>
    </HelmetProvider>
);
