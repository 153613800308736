import {
    Button,
    Container,
    FormControlLabel,
    FormLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    TextField
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext } from "react";
import Recaptcha from "react-google-recaptcha";
import { recaptchaSiteKey } from "../config/recaptcha_site_key";
import { Book } from "../context/book";
import { ClientCustomizations } from "../context/client_customizations";
import {
    SubscriptionRequestDetails,
    useSubscriptionRequestForm
} from "../services/hooks/use_subscription_request_form";
import { useSurveyQuestions } from "../services/hooks/use_survey_questions";
import { LabeledTextBox } from "./labeled_text_box";
import { Loading } from "./loading";
import { Message } from "./message";
import { TextBox } from "./text_box";
export const SubscriptionRequestForm: React.FC = () => {
    const { book } = useContext(Book);
    const { title = book.title, renewConfirmLabel } = useContext(ClientCustomizations);
    const questions = useSurveyQuestions();

    const {
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        submissionError,
        values,
    } = useSubscriptionRequestForm();
    const formatQuestionOptions
        = ({ hasOther, options, otherLabel }: { "hasOther": boolean; "options": string[]; "otherLabel": string }) =>
            options
                .concat(hasOther ? [otherLabel] : [])
                .map((text, index) => ({ text, "value": index }));

    const questionHandleChange = ({ label, options }: { "label": string; "options": string[] }, index: number) =>
        (_: any, option: any) => {
            const value = typeof option?.value === "number"
                ? option.value
                : options.length + 1;
            return setFieldValue(`survey[${index}]`, {
                "question": label,
                "answer": value as number < options.length
                    ? options[value as number]
                    : "",
                "isOther": value === options.length,
            });
        };

    return (<Paper>
        <Container>
            <Loading loading={isSubmitting} />
            <form onSubmit={handleSubmit} data-testid="formSubscription">
                <RadioGroup
                    name="doSubscribe"
                    onChange={(e, value) => setFieldValue(e.currentTarget.name, value === "true")}
                    value={values.doSubscribe}
                >
                    <FormControlLabel
                        control={<Radio />}
                        data-testid="subscribeYes"
                        label={`${renewConfirmLabel} ${title}`}
                        value={true}
                    />
                    <FormControlLabel
                        control={<Radio />}
                        data-testid="subscribeNo"
                        label="NO, I do not wish to Renew/Continue my Subscription"
                        value={false}
                    />
                </RadioGroup>
                <Grid container={true}>
                    {questions.map((question, index) => {
                        const questionError = (errors?.survey?.[index] as any)?.question;
                        const answerError = (errors?.survey?.[index] as any)?.answer;
                        return (
                            <Grid item={true} key={question.id} xs={12}>
                                <Autocomplete
                                    data-testid={`questionDropdown[${index}]`}
                                    getOptionLabel={(option) => option.text}
                                    id={`questionDropdown[${index}]`}
                                    options={formatQuestionOptions(question)}
                                    onChange={questionHandleChange(question, index)}
                                    placeholder={question.label}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        error={Boolean(questionError)}
                                        fullWidth={true}
                                        helperText={questionError}
                                        label={question.label}
                                        name={`survey[${index}]`}
                                        inputProps={{
                                            ...params.inputProps,
                                            "autoComplete": "new-password", // disable autocomplete and autofill
                                        }}
                                    />}
                                />
                                {values.survey[index] && values.survey[index].isOther && <TextField
                                    data-testid={`questionInput[${index}]`}
                                    error={Boolean(answerError)}
                                    fullWidth={true}
                                    helperText={answerError}
                                    name={`survey[${index}].answer`}
                                    onChange={handleChange}
                                />}
                            </Grid>
                        );
                    })}
                    {book.securityQuestion
                        ? <Grid item={true} xs={12} data-testid="emailPrompt">
                            {"Enter your "}
                            <strong>
                                {"Email Address"}
                            </strong>
                            {", and in lieu of a signature, "}
                            {"please provide a unique identifier which can be used for verification purposes"}
                        </Grid>
                        : <Grid item={true} xs={12} data-testid="emailPrompt">
                            {"Please enter your "}
                            <strong>
                                {"Email Address"}
                            </strong>
                            {" below "}
                        </Grid>
                    }
                    <Grid item={true} xs={12} sm={6}>
                        <TextBox<SubscriptionRequestDetails>
                            data-testid="email"
                            error={errors.email}
                            label="Email Address"
                            name="email"
                            onChange={handleChange}
                            placeholder="Email Address"
                            value={values.email}
                        />
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <TextBox<SubscriptionRequestDetails>
                            data-testid="emailAgain"
                            error={errors.emailAgain}
                            label="Re-Enter Email Address"
                            name="emailAgain"
                            onChange={handleChange}
                            placeholder="Re-Enter Email Address"
                            value={values.emailAgain}
                        />
                    </Grid>
                    {book.securityQuestion && <Grid item={true} xs={12}>
                        <LabeledTextBox<SubscriptionRequestDetails>
                            data-labeltestid="securityQuestion"
                            data-testid="securityAnswer"
                            error={errors.security?.answer}
                            label={book.securityQuestion}
                            name="security.answer"
                            onChange={handleChange}
                            placeholder="Please provide a value"
                            value={values.security.answer}
                        />
                    </Grid>}
                    <Grid item={true} xs={12}>
                        {errors.token && <FormLabel data-testid="recaptchaError" error={true}>
                            {errors.token}
                        </FormLabel>}
                        <Recaptcha
                            data-testid="recaptcha"
                            onChange={(token) => setFieldValue("token", token)}
                            sitekey={recaptchaSiteKey}
                        />
                    </Grid>
                    {submissionError && <Grid item={true} xs={12}>
                        <Message
                            content={submissionError}
                            data-testid="submissionError"
                            error={true}
                            header="An Error has occurred with your submission"
                        />
                    </Grid>}
                    <Grid item={true} xs={12}>
                        <Button
                            type="submit"
                            data-testid="submitSubscription"
                            endIcon={<SendIcon />}>
                            {"Submit"}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    </Paper >);
};
