// DECLARE @table_name VARCHAR(100) = 'Advanced Practice Providers'
// DECLARE @field_name VARCHAR(100) = 'Specialty'

// SELECT bbbtm.[Description]
// FROM [LMS_Files].[dbo].[BBB_FileList] AS bbbfl
//     INNER JOIN [LMS_Files].[dbo].[BBB_MainTree] AS bbbmt
//     ON bbbfl.[File_] = bbbmt.[File_]
//     INNER JOIN [LMS_Files].[dbo].[BBB_TreeMap] AS bbbtm
//     ON bbbmt.[TreeMap] = bbbtm.[MapName]
// WHERE bbbfl.[caption] = @table_name
//     AND bbbmt.[Description] = @field_name
//     AND bbbtm.[Type] = 'parent'
//     AND bbbtm.[Description] != 'Unspecified'
// ORDER BY bbbtm.[Description]

export const appSpecialties = [
    "Addiction Medicine",
    "Adolescent Medicine ",
    "Adult Medicine",
    "Aerospace Medicine",
    "Allergy",
    "Allergy & Immunology",
    "Anesthesiology",
    "Cardiac Electrophysiology",
    "Cardiovascular Disease",
    "Child Psychiatry",
    "Colon & Rectal Surgery ",
    "Critical Care Medicine",
    "Critical Care Surgery",
    "Critical Care, Pediatric",
    "Dermatology",
    "Diabetes",
    "Emergency Medicine",
    "Emergency Medicine, Pediatric",
    "Endocrinology, Diabetes & Metabolism",
    "Endocrinology, Pediatric",
    "Family Medicine",
    "Foot & Ankle, Orthopedics",
    "Gastroenterology",
    "Gastroenterology, Pediatric",
    "General Practice",
    "General Surgery",
    "Geriatric Medicine",
    "Gynecological Oncology",
    "Hand Surgery",
    "Hematology",
    "Hematology/Oncology",
    "Hematology/Oncology, Pediatric",
    "Hepatology",
    "HIV/AIDS Medicine",
    "Hospitalist",
    "Infectious Disease",
    "Infectious Disease, Pediatric",
    "Internal Medicine",
    "Interventional Cardiology",
    "Medical Oncology",
    "Neonatal-Perinatal Medicine",
    "Nephrology",
    "Neurological Surgery",
    "Neurology",
    "Nuclear Medicine",
    "Nutrition",
    "Obstetrics & Gynecology",
    "Occupational Medicine",
    "Ophthalmology",
    "Orthopedic Surgery",
    "Otolaryngology",
    "Otolaryngology, Pediatric",
    "Pain Management",
    "Pathology",
    "Pediatric Cardiology",
    "Pediatric Nephrology",
    "Pediatric Neurology",
    "Pediatric Surgery",
    "Pediatrics",
    "Physical Medicine & Rehabilitation",
    "Plastic Surgery",
    "Psychiatry",
    "Public Health & General Preventive Medicine",
    "Pulmonary Disease",
    "Pulmonology, Pediatric",
    "Radiation Oncology",
    "Radiology/Diagnostic Radiology",
    "Reproductive Endocrinology & Infertility",
    "Rheumatology",
    "Rheumatology, Pediatric",
    "Sleep Medicine",
    "Sports Medicine ",
    "Surgical Oncology",
    "Thoracic Surgery",
    "Transplant Surgery",
    "Trauma Surgery",
    "Urgent Care Medicine",
    "Urology",
    "Vascular & Interventional Radiology",
    "Vascular Surgery",
    "Women's Health",
];
