import React, { Fragment, FC } from "react";
import { Typography } from "@material-ui/core";

export type DefaultFooterProps = {
    "copyrightInfo": string;
    "emailAddress": string;
    "mailingAddress": string;
    "privacyPolicyUrl": string;
    "termsConditionsUrl"?: string;
};

export const DefaultFooter: FC<DefaultFooterProps> = ({
    copyrightInfo,
    emailAddress,
    mailingAddress,
    privacyPolicyUrl,
    termsConditionsUrl,
}) => (<Fragment>
    <Typography variant="subtitle2" style={{ "marginTop": "6em", "fontWeight": "bold" }}>
        <p data-testid="footerSupportMessage">
            {"Having trouble with this page? Please email "}
            <a href={`mailto:${emailAddress}`} data-testid="supportEmail">
                {emailAddress}
            </a>
            <br />
            {mailingAddress}
        </p>
    </Typography>
    <Typography variant="caption" style={{ "paddingBottom": "2em" }} data-testid="footerCopyright">
        {copyrightInfo}
        {termsConditionsUrl && <a
            data-testid="footerTermsConditions"
            href={termsConditionsUrl}
            rel="noopener noreferrer"
            style={{ "float": "right", "paddingLeft":"15px" }}
            target="_blank"
        >
            {"Terms & Conditions"}
        </a>}
        <a
            data-testid="footerPrivacyPolicy"
            href={privacyPolicyUrl}
            rel="noopener noreferrer"
            style={{ "float": "right" }}
            target="_blank"
        >
            {"Privacy Policy"}
        </a>
    </Typography>
</Fragment>);
