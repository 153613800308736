import { Button, Container, Grid, Paper } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import React,{ useContext } from "react";
import { usePhysicianSearchForm } from "../services/hooks/use_physician_search_form";
import { states } from "../services/options/states";
import { years } from "../services/options/years";
import { PhysicianSearchData } from "../types/requests/physician_search_data";
import { Dropdown } from "./dropdown";
import { Loading } from "./loading";
import { ManualFallbackDialog } from "./manual_fallback_dialog";
import { Message } from "./message";
import { TextBox } from "./text_box";
import { ClientCustomizations } from "../context/client_customizations";

export const PhysicianSearchForm: React.FC = () => {
    const {
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        submissionError,
        values,
    } = usePhysicianSearchForm();
    const {physicianHeaderLabel } = useContext(ClientCustomizations);
    const yearOptions = years.map((year) => ({ "text": year, "value": year }));

    return (<Paper>
        <Container>
            <Loading loading={isSubmitting} />
            <form onSubmit={handleSubmit} data-testid="formSearch">
                <Grid container={true}>
                    <Grid item={true} xs={12} data-testid="searchPrompt">                        
                        {physicianHeaderLabel}
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <TextBox<PhysicianSearchData>
                            data-testid="firstName"
                            error={errors.firstName}
                            label="First Name"
                            name="firstName"
                            onChange={handleChange}
                            placeholder="First Name"
                            value={values.firstName}
                        />
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <TextBox<PhysicianSearchData>
                            data-testid="lastName"
                            error={errors.lastName}
                            label="Last Name"
                            name="lastName"
                            onChange={handleChange}
                            placeholder="Last Name"
                            value={values.lastName}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Dropdown<PhysicianSearchData>
                            data-testid="state"
                            error={errors.state}
                            label="Mailing State"
                            name="state"
                            options={states}
                            placeholder="Select a State"
                            setFieldValue={setFieldValue}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Dropdown<PhysicianSearchData>
                            data-testid="birthYear"
                            error={errors.birthYear}
                            label="Birth Year"
                            name="birthYear"
                            options={yearOptions}
                            placeholder="Select a Year"
                            setFieldValue={setFieldValue}
                        />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Dropdown<PhysicianSearchData>
                            data-testid="gradYear"
                            error={errors.gradYear}
                            label="Medical School Graduation Year"
                            name="gradYear"
                            options={yearOptions}
                            placeholder="Select a Year"
                            setFieldValue={setFieldValue}
                        />
                    </Grid>
                    {submissionError && <Grid item={true} xs={12}>
                        <Message
                            content={submissionError}
                            data-testid="submissionError"
                            error={true}
                            header="An Error has occurred with your submission"
                        />
                    </Grid>}
                    <Grid item={true} xs={12}>
                        <Button
                            data-testid="submitSearch"
                            endIcon={<SendIcon />}
                            type="submit">
                            {"Submit"}
                        </Button>
                    </Grid>
                    {submissionError && <ManualFallbackDialog />}
                </Grid>
            </form>
        </Container>
    </Paper >);
};
