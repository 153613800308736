import { removeUndefinedProps } from "../services/remove_undefined_props";
import { SubscriptionRequestDetails } from "../services/hooks/use_subscription_request_form";
import { FormikErrors } from "formik";
import { validateEmail } from "./validate_email";

export const validateSubscriptionRequestDetails
    = async (entry: SubscriptionRequestDetails): Promise<FormikErrors<SubscriptionRequestDetails>> => {
        const surveyErrors = entry.survey
            .map(({ answer, isOther }) => answer
                ? {}
                : isOther
                    ? { "answer": "Please provide an Answer" }
                    : { "question": "Please select an Answer" }
            );
        const surveyPasses = surveyErrors
            .filter(({ answer, question }) => answer || question)
            .length === 0;
        return removeUndefinedProps({
            ...!entry.email
                ? { "email": "Please enter an Email Address" }
                : !validateEmail(entry.email)
                    ? { "email": "Please provide a valid Email Address" }
                    : entry.email !== entry.emailAgain
                        ? { "emailAgain": "Email Addresses must match" }
                        : {},
            ...!entry.security.question
                ? {}
                : {
                    "security": !entry.security.answer
                        ? { "answer": "Please answer the Verification Question" }
                        : undefined
                },
            "survey": surveyPasses
                ? undefined
                : surveyErrors,
            "token": entry.token
                ? undefined
                : "Please verify that you're not a robot",
        });
    };
