import { FormikErrors } from "formik";
import { removeUndefinedProps } from "../services/remove_undefined_props";
import { AppSearchData } from "../types/requests/app_search_data";
import { validateNpiNumber } from "./validate_npi_number";

export const validateAppSearch
    = async (entry: AppSearchData): Promise<FormikErrors<AppSearchData>> =>
        removeUndefinedProps({
            "firstName": !entry.firstName
                ? "Please provide your first name"
                : undefined,
            "lastName": !entry.lastName
                ? "Please provide your last name"
                : undefined,
            "npiNumber": validateNpiNumber(entry.npiNumber),
            "state": !entry.state
                ? "Please select the state in which you live"
                : undefined,
        });
