import { Container, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { App } from "../../../App";
import { theme } from "../../../themes/default";
import favicon from "./homepage_files/favicon/android-chrome-512x512.png";
import journalCover from "./homepage_files/main_jocp_logo.png";
import mailingLabel from "./homepage_files/label.png";

export const JOCP: React.FC = () => {
    const landingPageIntro = () => (<Fragment>
        <p>
            {`If you are a current subscriber and would like to renew your subscription, 
        please validate your information and complete the form below.`}
        </p>
    </Fragment>);
    return (<Fragment>
        <Container>
            <img src={journalCover} alt={"Renew Header"} style={{ "maxWidth": "100%" }} />
        </Container>
        <Container style={{ "maxWidth": "700px" }}>
            <App
                bookId="190"
                favicon={favicon}
                htmlTitle="Journal of Clinical Psychiatry"
                landingPageIntro={landingPageIntro}
                mailingLabel={mailingLabel}
                theme={theme}
            />
            <Typography
                variant="subtitle2"
                style={{ "marginTop": "10em", "paddingBottom": "2em", "textAlign": "center" }}
            >
                <a
                    data-testid="jocpFooterPrivacyPolicy"
                    href="https://www.psychiatrist.com/pages/indexprivacy.aspx"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {"Privacy Policy"}
                </a>
            </Typography>
        </Container>
    </Fragment>);
};
