import { PractitionerType } from "../../types/subscription/practitioner_type";

export const practitionerTypes: PractitionerType[] = [
    "Physician (MD/DO)",
    "Nurse Practitioner (NP)",
    "Physician’s Assistant (PA)",
    "Certified Nurse Midwife (CNM)",
    "Certified Registered Nurse Anesthetist (CRNA)",
    "Clinical Nurse Specialist (CNS)",
    "Nurse",
    "Pharmacist",
    "Dentist",
    "Optometrist",
];
