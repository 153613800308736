import React, { FC } from "react";
import { MemoryRouter, Route, useLocation } from "react-router-dom";
import { RootPath } from "../context/root_path";

export type TemplateRouteProps = { component?: FC; path: string; render?: () => JSX.Element };

export const TemplateRoute: FC<TemplateRouteProps> = ({ children, component, path, render }) => {
    const location = useLocation();
    return (
        <Route path={path} render={({ match }) => (
            <MemoryRouter initialEntries={[location]}>
                <RootPath.Provider value={match.path}>
                    {component
                        ? React.createElement(component)
                        : render
                            ? render()
                            : children}
                </RootPath.Provider>
            </MemoryRouter>
        )}>
        </Route>
    );
};
