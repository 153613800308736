import { env, ENV } from "../../config/environment";

type Host = {
    "domain": string;
    "apiKey": string;
    "orgId": number;
};

const local = {
    "domain": "https://www.medtargetsystem.local",    
    "apiKey": "181-2843-4DFA455A",
    "orgId":181
};
const dev = {
    "domain": "https://qa.medtargetsystem.com",
    "apiKey": "181-2843-4DFA455A",
    "orgId":181
};
const qa = {
    "domain": "https://qa.medtargetsystem.com",
    "apiKey": "181-2843-4DFA455A",
    "orgId":181
};
const stg = {
    "domain": "https://stg.medtargetsystem.com",
    "apiKey": "181-2843-4DFA455A",
    "orgId":181
};
const prod = {
    "domain": "https://www.medtargetsystem.com",
    "apiKey": "180-2843-4DFA455A",
    "orgId":180
};

export const config: {[T in ENV]: Host} = {
    dev,
    local,
    prod,
    qa,
    stg,
};

export function getHost(targetEnv: ENV): Host {
    return config[targetEnv];
}

export default config[env];

