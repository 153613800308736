import { useEffect, useState } from "react";
import { apiKey } from "../../config/api_key";
import { apiRoutes } from "../../config/api_routes";
import { Book } from "../../types/book";
import { PublicationsBookGetQueryParams } from "../../types/query_params/publications_book_get_query_params";
import { alternateSubscriptionPermissions } from "../book/alternate_subscription_permissions";
import { apiGet } from "../http/api_get";

export function useBook(id: string) {
    const [book, setBook] = useState({} as Book);
    useEffect(
        () => {
            apiGet<PublicationsBookGetQueryParams, Book>({
                "headers": { "x-api-key": apiKey },
                "params": { id },
                "url": apiRoutes.getBook,
            }).then(({ data }) => {
                return setBook(data);
            });
        },
        [id],
    );
    const bookLoaded = Object.keys(book).length > 0;
    const { permitApps, permitManual, permitPhysicians } = alternateSubscriptionPermissions(book);
    return {
        book,
        permitApps,
        permitManual,
        permitPhysicians,
        bookLoaded,
    };
}
