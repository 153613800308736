import React, { Fragment } from "react";

export type RenderListWithBreaksProps = {
    "items": string[];
    "name"?: string;
};

const randomName = () => Math.random().toString();

export const RenderListWithBreaks: React.FC<RenderListWithBreaksProps> = ({ items, name = randomName() }) => (
    <Fragment>
        {items.map((item, index) => (
            <Fragment key={name + index}>
                {index > 0 && <br />}
                {item}
            </Fragment>
        ))}
    </Fragment>
);
