import { FormikErrors } from "formik";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Account } from "../../context/account";
import { AccountHcpData } from "../../types/requests/account_hcp_data";
import { useForm } from "./use_form";
import { useRoutes } from "./use_routes";

export function useSearchForm<T extends AccountHcpData>({
    initialValues,
    validate,
}: {
    "initialValues": T;
    "validate": (data: T) => Promise<FormikErrors<T>>;
}) {
    const { setAccountBySearch } = useContext(Account);
    const routes = useRoutes();
    const history = useHistory();
    const [submissionError, setError] = useState("");
    const onSubmit = async (searchEntry: T) => {
        try {
            setError("");
            await setAccountBySearch(searchEntry);
            history.push(routes.accountRenewal);
        } catch (err) {
            setError("No Account could be found with that information");
        }
    };

    const form = useForm({
        initialValues,
        onSubmit,
        validate,
    });
    return { submissionError, ...form };
}
