import axios, { AxiosPromise } from "axios";

export type ApiPostProps<T> = {
    "body": T;
    "headers"?: any;
    "url": string;
};

export function apiPost<T, U>({ url, body, headers = {} }: ApiPostProps<T>) {
    return axios.post(url, body, { headers }) as AxiosPromise<U>;
}
